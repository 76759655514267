import { useEffect, useRef, useState } from 'react';

const useHorizontalScrollVisibility = (error = 0) => {
  const [isBlurLeftVisible, setIsBlurLeftVisible] = useState(false);
  const [isBlurRightVisible, setIsBlurRightVisible] = useState(false);
  const innerContainerRef = useRef<HTMLDivElement>(null);

  const updateState = (target: HTMLDivElement) => {
    const { scrollLeft, scrollWidth, offsetWidth } = target;
    const scrollRight = scrollWidth - scrollLeft - offsetWidth - error;

    if (scrollLeft === 0) {
      setIsBlurLeftVisible(false);
    } else {
      setIsBlurLeftVisible(true);
    }

    if (scrollRight <= 0) {
      setIsBlurRightVisible(false);
    } else {
      setIsBlurRightVisible(true);
    }
  };

  const handleOnScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const target = e.target as HTMLDivElement;
    updateState(target);
  };

  useEffect(() => {
    if (innerContainerRef.current) {
      const target = innerContainerRef.current;
      updateState(target);
    }
  });

  return {
    isBlurLeftVisible,
    isBlurRightVisible,
    innerContainerRef,
    handleOnScroll,
  };
};

export default useHorizontalScrollVisibility;
