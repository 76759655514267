import { useContext } from 'react';
import { AppContext } from '../../providers';
import { PAGES } from './constants';
import { handleButtonsClick } from '../../utils';
import useResetState from '../../hooks/useResetState';

const usePageWrapper = () => {
  const { appState, appActions } = useContext(AppContext);
  const { resetCurrentStepState } = useResetState();
  const { pages, stepper } = appState;
  const { currentStep } = stepper;

  const currentPageData = pages[currentStep];
  const pageName = currentPageData?.contentTypeUid;
  const Page = PAGES[pageName] || '';
  // TODO: default value should be a element with a message
  const hideStepper = currentPageData?.isStandalonePage;

  const goToNextPage = () => {
    if (currentStep === pages.length - 1) {
      return;
    }
    appActions.updateStepperInfo({ currentStep: currentStep + 1 });
  };

  const goToPreviousPage = () => {
    if (currentStep === 0) {
      return;
    }
    resetCurrentStepState(pageName);
    appActions.updateStepperInfo({ currentStep: currentStep - 1 });
  };

  const onKeyDownNextPage = (event: React.KeyboardEvent<HTMLDivElement>) => {
    handleButtonsClick(event, goToNextPage);
  };

  const onKeyDownPreviousPage = (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    handleButtonsClick(event, goToPreviousPage);
  };

  return {
    Page,
    pageData: currentPageData,
    hideStepper,
    status: appState.status,
    currentStep,
    goToNextPage,
    goToPreviousPage,
    onKeyDownNextPage,
    onKeyDownPreviousPage,
  };
};

export default usePageWrapper;
