import styled from 'styled-components';
import { COLORS, FONT_SIZES } from '../../../../../utils/constants/styles';

export const PageContentContainer = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  align-items: flex-start;
  margin-top: -4px;
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 54%;
  min-width: 54%;
  max-width: 54%;
`;

export const PageOptionalTitle = styled.div`
  color: ${COLORS.DARK_GRAY};
  font-size: ${FONT_SIZES.REGULAR};
`;

export const PageTitle = styled.div`
  color: ${COLORS.DARK_GRAY};
  font-size: ${FONT_SIZES.DESKTOP_HEADING};
  font-weight: 700;
  line-height: 31px;
  margin-bottom: 16px;
`;

export const ImageWrapper = styled.div`
  position: relative;
  flex-basis: 46%;
  min-width: 46%;
  max-width: 46%;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const ImageContent = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  margin: 16px;
  background-color: ${COLORS.WHITE};
  padding: 16px;
  color: ${COLORS.DARK_GRAY};
`;

export const ImageTitle = styled.div`
  font-size: ${FONT_SIZES.MOBILE_HEADING};
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 4px;
`;

export const ImageDescription = styled.div`
  font-size: ${FONT_SIZES.SMALL};
  line-height: 16px;
`;

export const NextAndBackButtonsWrapper = styled.div`
  margin-top: 24px;
`;

export const DefaultListWrapper = styled.div`
  margin-top: 16px;
`;
