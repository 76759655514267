import styled from 'styled-components';
import { COLORS } from '../../../utils/constants/styles';
import { CLASSES_LIST } from '../../../utils/constants';
import { HideScrollBar } from '../../../utils/styles';

export const SwiperContainer = styled.div`
  position: relative;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
`;

export const SwiperInner = styled.div`
  display: flex;
  overflow-x: auto;
  gap: 16px;
  ${HideScrollBar}
`;

export const SwiperItem = styled.div`
  white-space: nowrap;
  padding: 12px 34px; // TODO: UPDATE THIS
  border: 1px solid ${COLORS.PRIMARY};
  border-radius: 100px;
  color: ${COLORS.PRIMARY};
  font-weight: 700;

  &.${CLASSES_LIST.ACTIVE} {
    background-color: ${COLORS.PRIMARY};
    color: ${COLORS.WHITE};
  }
`;
