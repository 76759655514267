import { ProjectedValue, Timeline } from '../../pages/timeline/timeline.model';
import {
  getPlaceholderDesktopMainImage,
  getPlaceHolderThumbNail,
} from '../../utils';
import {
  FunctionVoid,
  FunctionWith3Args,
  FunctionWithProp,
} from '../../utils/common.model';
import { IMAGE_TYPES } from '../../utils/constants';
import Image from '../image';
import {
  ListCard,
  ListCardContent,
  ListCardDescription,
  ListCardImageWrapper,
  ListCardTitle,
  ListContainer,
} from './styles';

const ListCards: React.FC<{
  data: Timeline;
  onCardClick: FunctionWithProp<ProjectedValue>;
  handleOnMouseLeave: FunctionVoid;
  handleOnMouseEnter: FunctionWith3Args<string, string, string>;
}> = ({ data, onCardClick, handleOnMouseEnter, handleOnMouseLeave }) => {
  const { projectedValues } = data;
  const onMouseEnter =
    (mainImageUrl: string, label: string, descriptionOnImage: string) => () =>
      handleOnMouseEnter(mainImageUrl, label, descriptionOnImage);
  const onCardItemClick = (item: any) => () => onCardClick(item);

  return (
    <ListContainer>
      {projectedValues.map((item) => {
        const {
          label,
          description,
          imageDesktopSmall,
          imageDesktopMain,
          descriptionOnImage,
        } = item;
        const placeholderThumbNailUrl = getPlaceHolderThumbNail();
        const { url = placeholderThumbNailUrl, title } =
          imageDesktopSmall || {};
        const desktopMainImagePlaceholder = getPlaceholderDesktopMainImage();
        const { url: mainImageUrl = desktopMainImagePlaceholder } =
          imageDesktopMain || {};
        const isDescriptionAvailable = !!(
          description && description.length > 0
        );
        const isActive = item.isSelected ? 'active' : '';

        return (
          <ListCard
            key={label}
            className={`${isActive}`}
            onClick={onCardItemClick(item)}
            isDescriptionAvailable={isDescriptionAvailable}
          >
            <ListCardImageWrapper
              data-testid="list-card-image"
              onMouseEnter={onMouseEnter(
                mainImageUrl,
                label,
                descriptionOnImage
              )}
              onMouseLeave={handleOnMouseLeave}
            >
              <Image
                src={url}
                alt={title}
                objectFit="cover"
                imageType={IMAGE_TYPES.THUMBNAIL}
              />
            </ListCardImageWrapper>
            <ListCardContent>
              <ListCardTitle>{label}</ListCardTitle>
              {isDescriptionAvailable && (
                <ListCardDescription>{description}</ListCardDescription>
              )}
            </ListCardContent>
          </ListCard>
        );
      })}
    </ListContainer>
  );
};

export default ListCards;
