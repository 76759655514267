import styled from 'styled-components';
import { COLORS, FONT_SIZES } from '../../../utils/constants/styles';
import { CLASSES_LIST } from '../../../utils/constants';
import { HideScrollBar } from '../../../utils/styles';

export const MobileSubStepperContainer = styled.div`
  white-space: nowrap;
  overflow-x: hidden;
  position: relative;
`;

export const SubStepperInnerContainer = styled.div`
  display: flex;
  border: 0.5px solid ${COLORS.MEDIUM_GRAY};
  border-radius: 2px;
  padding: 10px 0 8px 8px;
  overflow-x: auto;
  ${HideScrollBar}
`;

export const SubStep = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TimelineWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2px;
`;

export const Circle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${COLORS.PRIMARY};

  &.${CLASSES_LIST.CURRENT} {
    width: 12px;
    height: 12px;
    margin-top: -2px;
  }
`;

export const Line = styled.div`
  background-color: ${COLORS.PRIMARY};
  height: 0.5px;
  flex: 1; // TODO: Learn about it
`;

export const LabelsContainer = styled.div`
  color: ${COLORS.NEUTRAL_GRAY_COLD};
  font-size: ${FONT_SIZES.SMALL};
  line-height: 16px;
  padding-right: 16px;
`;

export const SubStepTitle = styled.div`
  &.${CLASSES_LIST.CURRENT} {
    font-weight: 700;
  }
`;

export const SubStepValue = styled.div`
  height: 16px;
`;
