import { Input } from '@digital-retail/journey-ui-kit';
import useDevice from '../../hooks/useDevice';
import { Page } from '../pages.model';
import { MeasurementPage } from './measurement.model';
import {
  InfoContainer,
  InfoText,
  InfoTitle,
  InputWrapper,
  InputsContainer,
  LabelContainer,
  LabelIcon,
  LabelText,
  PageColumn1,
  PageContainer,
  PageContent,
  PageImage,
  PageTitle,
} from './styles';
import useMeasurement from './useMeasurement';
import { API_STATUS, INPUT_MODES } from '../../utils/constants';
import NextAndBackButtons from '../../components/next-and-back-buttons';
import Loader from '../../components/loader';

const Measurement: React.FC<{ data: Page }> = ({ data }) => {
  const pageData = data as MeasurementPage;
  const {
    pageTitle,
    nextButtonText,
    backButtonText,
    imageUrl,
    imageTitle,
    noteTitle,
    noteDescription,
    inputsData,
    selectionApiStatus,
    isNextButtonDisabled,
    goToNextPage,
    goToPreviousPage,
  } = useMeasurement(pageData);
  const { isMobile } = useDevice();

  if (selectionApiStatus === API_STATUS.ERROR) {
    return <div>Error Loading Data...</div>;
  }
  // TODO: Add Error component

  return (
    <>
      {selectionApiStatus === API_STATUS.LOADING && <Loader />}
      <PageContainer>
        <PageTitle>{pageTitle}</PageTitle>
        <PageContent>
          <PageColumn1>
            <InputsContainer>
              {inputsData.map((inputData) => {
                return (
                  <InputWrapper key={inputData.label}>
                    <LabelContainer>
                      <LabelIcon
                        src={inputData?.labelIcon?.url}
                        alt={inputData?.label}
                      />
                      <LabelText>{inputData.label}</LabelText>
                    </LabelContainer>
                    <Input
                      value={inputData.value}
                      error={inputData.isError}
                      placeholder={inputData.placeholderValue}
                      type={inputData.type}
                      inputMode={INPUT_MODES[inputData.type]}
                      helper={inputData.errorMessage}
                      onChange={(value) => inputData.onChange(inputData, value)}
                    />
                  </InputWrapper>
                );
              })}
            </InputsContainer>
            {!isMobile && (
              <NextAndBackButtons
                dataTestId="next-and-back-buttons-desktop"
                nextButtonText={nextButtonText}
                backButtonText={backButtonText}
                isDisabled={isNextButtonDisabled}
                goToNextPageFn={goToNextPage}
                goToPreviousPageFn={goToPreviousPage}
                useCustomFunctions={true}
              />
            )}
          </PageColumn1>
          <PageImage src={imageUrl} alt={imageTitle}></PageImage>
          <InfoContainer>
            <InfoTitle>{noteTitle}</InfoTitle>
            <InfoText>{noteDescription}</InfoText>
          </InfoContainer>
          {isMobile && (
            <NextAndBackButtons
              dataTestId="next-and-back-buttons-mobile"
              nextButtonText={nextButtonText}
              backButtonText={backButtonText}
              isDisabled={isNextButtonDisabled}
              goToNextPageFn={goToNextPage}
              goToPreviousPageFn={goToPreviousPage}
              useCustomFunctions={true}
            />
          )}
        </PageContent>
      </PageContainer>
    </>
  );
};

export default Measurement;
