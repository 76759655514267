import { Page } from '../../pages/pages.model';

export const initialPageData = {
  pages: [
    {
      contentTypeUid: 'journeys_page_introduction',
      backButtonText: '',
      cards: [
        {
          title: 'Personaliza tu ventana',
          description:
            '<p>Selecciona las características y las medidas de tu ventana.</p>',
          imageDesktop: {
            contentType: 'image/jpeg',
            filename: 'page_1_card_1.jpeg',
            title: 'page_1_card_1.jpeg',
            url: 'https://images.contentstack.io/v3/assets/blt34d59f5b52e53f95/blt58393265938c11e2/653f44c626f138001808e669/page_1_card_1.jpeg',
          },
        },
        {
          title: 'Opciones de instalación',
          description:
            '<p>Si incluyes la instalación, te contactaremos en <strong>48 hrs hábiles</strong> para coordinar una visita.</p>',
          imageDesktop: {
            contentType: 'image/png',
            filename: 'page_2_card_2.png',
            title: 'page_2_card_2.png',
            url: 'https://images.contentstack.io/v3/assets/blt34d59f5b52e53f95/blt5441166b35da77f9/653f44d826f138001808e66d/page_2_card_2.png',
          },
        },
        {
          title: 'Agrega al Carro',
          description:
            '<p>Revisa tu pedido y agrega al Carro. Fabricaremos y enviaremos tu ventana lo antes posible.</p>',
          imageDesktop: {
            contentType: 'image/jpeg',
            filename: 'page-1-card-3.jpeg',
            title: 'page-1-card-3.jpeg',
            url: 'https://images.contentstack.io/v3/assets/blt34d59f5b52e53f95/bltcced3d45255b0a98/653f44e6039e2c00189df1ce/page-1-card-3.jpeg',
          },
        },
      ],
      isStandalonePage: true,
      nextButtonText: '¡Empecemos!',
      pageTitle:
        '<p>Compra <strong>ventanas a medida</strong></p><p>Simplificamos el proceso de <strong>elección de ventanas a medida</strong> con instrucciones sencillas y pasos fáciles de seguir.</p>',
      showcaseImageDesktop: {
        contentType: 'image/jpeg',
        filename: 'page-1-showcase.jpeg',
        title: 'page-1-showcase.jpeg',
        url: 'https://images.contentstack.io/v3/assets/blt34d59f5b52e53f95/bltd99365fd3b5bffcf/65730970841cdfbba274cb93/page-1-showcase.jpeg',
      },
      stepperTitleDesktop: 'Introduction',
      stepperTitleMobile: 'Intro',
      title: 'windows flow into page - test',
    },
    {
      contentTypeUid: 'journeys_page_timeline',
      backButtonText: 'Volver',
      isStandalonePage: false,
      nextButtonText: 'Continuar',
      pageTitle: '',
      stepperTitleDesktop: 'Personaliza tu ventana',
      stepperTitleMobile: 'Personaliza tu ventana',
      timelines: [
        {
          optionalTitle: 'VENTANAS A MEDIDA',
          title: 'Selecciona el tipo de producto que necesitas',
          viewType: 'List',
          facetAttributeName: 'product.attribute.Tipo_de_ventana',
          label: 'Producto:',
          defaultImageDesktop: {
            title: 'default-desktop-somx.png',
            contentType: 'image/png',
            filename: 'default-desktop-somx.png',
            url: 'https://images.contentstack.io/v3/assets/blt6df9971ff924b699/blt2b0093897f1a61df/66f1a1324847fd80db25fac7/default-desktop-somx.png',
          },
          defaultImageMobile: {
            title: 'default-image.jpeg',
            contentType: 'image/jpeg',
            filename: 'default-image.jpeg',
            url: 'https://images.contentstack.io/v3/assets/blt6df9971ff924b699/bltf65c1b22fdb8e0ae/66f1a10bc9f9c7382353188f/default-image.jpeg',
          },
          projectedValues: [
            {
              facetValue: 'cancel',
              label: 'Cancel',
              description: '',
              imageDesktopMain: {
                title: 'desktop-producto-1.jpeg',
                contentType: 'image/jpeg',
                filename: 'desktop-producto-1.jpeg',
                url: 'https://images.contentstack.io/v3/assets/blt6df9971ff924b699/bltfe66269f72165c9e/66f1725463d5df4873808ef4/desktop-producto-1.jpeg',
              },
              imageDesktopSmall: {
                title: 'latest thumb cancel.png',
                contentType: 'image/png',
                filename: 'latest_thumb_cancel.png',
                url: 'https://images.contentstack.io/v3/assets/blt6df9971ff924b699/blta8b953290af9ec82/66f57765242fb95b52f987d0/latest_thumb_cancel.png',
              },
              imageMobile: {
                title: 'ventanas.jpeg',
                contentType: 'image/jpeg',
                filename: 'ventanas.jpeg',
                url: 'https://images.contentstack.io/v3/assets/blt6df9971ff924b699/blt8e4733a722ff6057/66f172d73666b0c133bac39f/ventanas.jpeg',
              },
              descriptionOnImage:
                "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.",
            },
            {
              facetValue: 'ventana',
              label: 'Ventana',
              description: '',
              imageDesktopMain: {
                title: 'desktop-producto-2.jpeg',
                contentType: 'image/jpeg',
                filename: 'desktop-producto-2.jpeg',
                url: 'https://images.contentstack.io/v3/assets/blt6df9971ff924b699/blt75b0b279401d42c5/66f17254ed7186f22dfb8f9b/desktop-producto-2.jpeg',
              },
              imageDesktopSmall: {
                title: 'latest thumb ventanta 1.png',
                contentType: 'image/png',
                filename: 'latest_thumb_ventanta_1.png',
                url: 'https://images.contentstack.io/v3/assets/blt6df9971ff924b699/blt304c01e89e373862/66f577653816cb14bdcffc4b/latest_thumb_ventanta_1.png',
              },
              imageMobile: null,
              descriptionOnImage: '',
            },
          ],
        },
        {
          optionalTitle: '',
          title: 'Selecciona la línea',
          viewType: 'Grid',
          facetAttributeName: 'product.attribute.Línea_ventana_a_medida',
          label: 'Línea:',
          defaultImageDesktop: {
            title: 'desktop-default-image-somx.png',
            contentType: 'image/png',
            filename: 'desktop-default-image-somx.png',
            url: 'https://images.contentstack.io/v3/assets/blt6df9971ff924b699/blt3d68c5cebc3570b4/66f28527242fb96db8f96ab0/desktop-default-image-somx.png',
          },
          defaultImageMobile: {
            title: 'default-image.jpeg',
            contentType: 'image/jpeg',
            filename: 'default-image.jpeg',
            url: 'https://images.contentstack.io/v3/assets/blt6df9971ff924b699/bltf65c1b22fdb8e0ae/66f1a10bc9f9c7382353188f/default-image.jpeg',
          },
          projectedValues: [
            {
              facetValue: 'luxor',
              label: 'Luxor',
              description: '',
              imageDesktopMain: {
                title: 'desktop-default-image-somx.png',
                contentType: 'image/png',
                filename: 'desktop-default-image-somx.png',
                url: 'https://images.contentstack.io/v3/assets/blt6df9971ff924b699/blt3d68c5cebc3570b4/66f28527242fb96db8f96ab0/desktop-default-image-somx.png',
              },
              imageDesktopSmall: {
                title: 'luxor-thumb.png',
                contentType: 'image/png',
                filename: 'luxor-thumb.png',
                url: 'https://images.contentstack.io/v3/assets/blt6df9971ff924b699/bltb2a9aacff08b2fb7/66f28526bb79b7e79441464b/luxor-thumb.png',
              },
              imageMobile: {
                title: 'default-image.jpeg',
                contentType: 'image/jpeg',
                filename: 'default-image.jpeg',
                url: 'https://images.contentstack.io/v3/assets/blt6df9971ff924b699/bltf65c1b22fdb8e0ae/66f1a10bc9f9c7382353188f/default-image.jpeg',
              },
              descriptionOnImage:
                "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.",
            },
            {
              facetValue: 'intermedia',
              label: 'Intermedia',
              description: '',
              imageDesktopMain: {
                title: 'desktop-default-image-somx.png',
                contentType: 'image/png',
                filename: 'desktop-default-image-somx.png',
                url: 'https://images.contentstack.io/v3/assets/blt6df9971ff924b699/blt3d68c5cebc3570b4/66f28527242fb96db8f96ab0/desktop-default-image-somx.png',
              },
              imageDesktopSmall: {
                title: 'luxor-thumb.png',
                contentType: 'image/png',
                filename: 'luxor-thumb.png',
                url: 'https://images.contentstack.io/v3/assets/blt6df9971ff924b699/bltb2a9aacff08b2fb7/66f28526bb79b7e79441464b/luxor-thumb.png',
              },
              imageMobile: {
                title: 'default-image.jpeg',
                contentType: 'image/jpeg',
                filename: 'default-image.jpeg',
                url: 'https://images.contentstack.io/v3/assets/blt6df9971ff924b699/bltf65c1b22fdb8e0ae/66f1a10bc9f9c7382353188f/default-image.jpeg',
              },
              descriptionOnImage: '',
            },
          ],
        },
        {
          optionalTitle: '',
          title: 'Elige el sistema de apertura',
          viewType: 'Grid',
          facetAttributeName: 'product.attribute.Sistema_de_apertura_ventana',
          label: 'Apertura:',
          defaultImageDesktop: null,
          defaultImageMobile: null,
          projectedValues: [
            {
              facetValue: 'puerta corredera 4 hojas',
              label: 'Corredizo 4 hojas',
              description: '',
              imageDesktopMain: {
                title: 'default image desktop.jpeg',
                contentType: 'image/jpeg',
                filename: 'default_image_desktop.jpeg',
                url: 'https://images.contentstack.io/v3/assets/blt6df9971ff924b699/bltac81ed3b3afa2230/66f2957fe39723eefa3908bf/default_image_desktop.jpeg',
              },
              imageDesktopSmall: {
                title: 'thumb-4 hojas somx.png',
                contentType: 'image/png',
                filename: 'thumb-4_hojas_somx.png',
                url: 'https://images.contentstack.io/v3/assets/blt6df9971ff924b699/blte9ed036d23e1cecb/66f2945df9cb266e2543cd22/thumb-4_hojas_somx.png',
              },
              imageMobile: null,
              descriptionOnImage: '',
            },
            {
              facetValue: 'puerta corredera 2 hojas',
              label: 'Corredizo 2 hojas',
              description: '',
              imageDesktopMain: null,
              imageDesktopSmall: null,
              imageMobile: null,
              descriptionOnImage: '',
            },
            {
              facetValue: 'puerta corredera 3 hojas',
              label: 'Corredizo 3 hojas',
              description: '',
              imageDesktopMain: null,
              imageDesktopSmall: null,
              imageMobile: null,
              descriptionOnImage: '',
            },
            {
              facetValue: 'puerta abatible',
              label: 'Puerta abatible',
              description: '',
              imageDesktopMain: null,
              imageDesktopSmall: null,
              imageMobile: null,
              descriptionOnImage: '',
            },
            {
              facetValue: 'ventana oscilobatiente',
              label: 'Oscilobatiente',
              description: '',
              imageDesktopMain: {
                title: 'default image desktop.jpeg',
                contentType: 'image/jpeg',
                filename: 'default_image_desktop.jpeg',
                url: 'https://images.contentstack.io/v3/assets/blt6df9971ff924b699/bltac81ed3b3afa2230/66f2957fe39723eefa3908bf/default_image_desktop.jpeg',
              },
              imageDesktopSmall: {
                title: 'thumb pureta somx.png',
                contentType: 'image/png',
                filename: 'thumb_pureta_somx.png',
                url: 'https://images.contentstack.io/v3/assets/blt6df9971ff924b699/blt948e5d21c951b6af/66f2951993399f351c8ab019/thumb_pureta_somx.png',
              },
              imageMobile: null,
              descriptionOnImage: '',
            },
            {
              facetValue: 'corredizo monoriel',
              label: 'Corredizo monoriel',
              description: '',
              imageDesktopMain: null,
              imageDesktopSmall: null,
              imageMobile: null,
              descriptionOnImage: '',
            },
            {
              facetValue: 'Proyectante',
              label: 'Proyectante',
              description: '',
              imageDesktopMain: null,
              imageDesktopSmall: null,
              imageMobile: null,
              descriptionOnImage: '',
            },
            {
              facetValue: 'ventana doble oscilobatiente/doble batiente',
              label: 'Doble Batiente',
              description: '',
              imageDesktopMain: null,
              imageDesktopSmall: null,
              imageMobile: null,
              descriptionOnImage: '',
            },
            {
              facetValue: 'Fijo',
              label: 'Fijo',
              description: '',
              imageDesktopMain: null,
              imageDesktopSmall: null,
              imageMobile: null,
              descriptionOnImage: '',
            },
            {
              facetValue: 'Ventana corredera 2 hojas',
              label: 'Ventana corredera 2 hojas',
              description: '',
              imageDesktopMain: null,
              imageDesktopSmall: null,
              imageMobile: null,
              descriptionOnImage: '',
            },
          ],
        },
        {
          optionalTitle: '',
          title: 'Elige la movilidad',
          viewType: 'List',
          facetAttributeName: 'product.attribute.Operación_ventana',
          label: 'Movilidad:',
          defaultImageDesktop: null,
          defaultImageMobile: null,
          projectedValues: [
            {
              facetValue: 'Izquierda - Derecha',
              label: 'Izquierda - Derecha',
              description: '',
              imageDesktopMain: null,
              imageDesktopSmall: null,
              imageMobile: null,
              descriptionOnImage: '',
            },
            {
              facetValue: 'Fijo',
              label: 'Fijo',
              description: '',
              imageDesktopMain: null,
              imageDesktopSmall: null,
              imageMobile: null,
              descriptionOnImage: '',
            },
            {
              facetValue: 'Movil - Movil',
              label: 'Móvil - Móvil',
              description: '',
              imageDesktopMain: null,
              imageDesktopSmall: null,
              imageMobile: null,
              descriptionOnImage: '',
            },
            {
              facetValue: 'Exterior',
              label: 'Exterior',
              description: '',
              imageDesktopMain: null,
              imageDesktopSmall: null,
              imageMobile: null,
              descriptionOnImage: '',
            },
            {
              facetValue: 'Fijo - Movil - Movil - Fijo',
              label: 'Fijo - Móvil - Mvil - Fijo',
              description: '',
              imageDesktopMain: null,
              imageDesktopSmall: null,
              imageMobile: null,
              descriptionOnImage: '',
            },
            {
              facetValue: 'Fija - Movil',
              label: 'Fija - Móvil',
              description: '',
              imageDesktopMain: null,
              imageDesktopSmall: null,
              imageMobile: null,
              descriptionOnImage: '',
            },
            {
              facetValue: 'Movil - Movil - Fijo',
              label: 'Móvil - Móvil - Fijo',
              description: '',
              imageDesktopMain: null,
              imageDesktopSmall: null,
              imageMobile: null,
              descriptionOnImage: '',
            },
            {
              facetValue: 'OX',
              label: 'OX',
              description: '',
              imageDesktopMain: null,
              imageDesktopSmall: null,
              imageMobile: null,
              descriptionOnImage: '',
            },
            {
              facetValue: 'XO',
              label: 'XO',
              description: '',
              imageDesktopMain: null,
              imageDesktopSmall: null,
              imageMobile: null,
              descriptionOnImage: '',
            },
          ],
        },
        {
          optionalTitle: '',
          title: 'Selecciona el color del marco',
          viewType: 'Grid',
          facetAttributeName: 'product.attribute.Color_ventana',
          label: 'Color:',
          defaultImageDesktop: null,
          defaultImageMobile: null,
          projectedValues: [
            {
              facetValue: 'Blanco',
              label: 'Blanco',
              description: '',
              imageDesktopMain: null,
              imageDesktopSmall: null,
              imageMobile: null,
              descriptionOnImage: '',
            },
            {
              facetValue: 'Foliado',
              label: 'Foliado',
              description: '',
              imageDesktopMain: null,
              imageDesktopSmall: null,
              imageMobile: null,
              descriptionOnImage: '',
            },
          ],
        },
        {
          optionalTitle: '',
          title: 'Elige tipo de cristal',
          viewType: 'Grid',
          facetAttributeName: 'product.attribute.Tipo_de_vidrio_ventana',
          label: 'Cristal:',
          defaultImageDesktop: null,
          defaultImageMobile: null,
          projectedValues: [
            {
              facetValue: 'Vidrio Monolitico',
              label: 'Monolítico',
              description: '',
              imageDesktopMain: null,
              imageDesktopSmall: null,
              imageMobile: null,
              descriptionOnImage: '',
            },
            {
              facetValue: 'Vidrio Panel',
              label: 'Vidrio Panel',
              description: '',
              imageDesktopMain: null,
              imageDesktopSmall: null,
              imageMobile: null,
              descriptionOnImage: '',
            },
            {
              facetValue: 'Vidrio Termopanel',
              label: 'Termopanel',
              description: '',
              imageDesktopMain: null,
              imageDesktopSmall: null,
              imageMobile: null,
              descriptionOnImage: '',
            },
          ],
        },
        {
          optionalTitle: '',
          title: 'Selecciona el color del panel',
          viewType: 'Grid',
          facetAttributeName:
            'product.attribute.Color_cristal_ventana_a_medida',
          label: 'Color panel:',
          defaultImageDesktop: null,
          defaultImageMobile: null,
          projectedValues: [
            {
              facetValue: 'Satinado',
              label: 'Satinado',
              description: '',
              imageDesktopMain: null,
              imageDesktopSmall: null,
              imageMobile: null,
              descriptionOnImage: '',
            },
            {
              facetValue: 'Claro',
              label: 'Claro',
              description: '',
              imageDesktopMain: null,
              imageDesktopSmall: null,
              imageMobile: null,
              descriptionOnImage: '',
            },
          ],
        },
      ],
      title: 'windows flow- timeline page',
    },
    {
      contentTypeUid: 'journeys_page_measurement',
      backButtonText: 'Volver',
      imageDesktop: {
        contentType: 'image/jpeg',
        filename: 'windows_step_2_image.jpeg',
        title: 'windows_step_2_image.jpeg',
        url: 'https://images.contentstack.io/v3/assets/blt34d59f5b52e53f95/blte6e01d077a7bd287/653f4cbd0ae856001b131b8c/windows_step_2_image.jpeg',
      },
      inputFields: [
        {
          type: 'number',
          labelIcon: {
            contentType: 'image/png',
            filename: 'windows_flow_step_2_label_1.png',
            title: 'windows_flow_step_2_label_1.png',
            url: 'https://images.contentstack.io/v3/assets/blt34d59f5b52e53f95/blt404fe6edf840bf34/653f4c79c2b34d001be73012/windows_flow_step_2_label_1.png',
          },
          label: 'Ancho (cm)',
          placeholderValue: 'Ej. 280',
          attributeName: 'ancho',
          measurementUnit: 'cm',
        },
        {
          type: 'number',
          labelIcon: {
            contentType: 'image/jpeg',
            filename: 'windows_flow_step_2_label_2.jpeg',
            title: 'windows_flow_step_2_label_2.jpeg',
            url: 'https://images.contentstack.io/v3/assets/blt34d59f5b52e53f95/bltf1d3a4d23761b692/653f4c9b1680320407c39f88/windows_flow_step_2_label_2.jpeg',
          },
          label: 'Alto (cm)',
          placeholderValue: 'Ej. 180',
          attributeName: 'alto',
          measurementUnit: 'cm',
        },
      ],
      isStandalonePage: false,
      nextButtonText: 'Continuar',
      noteDescription:
        'Ingrese las dimensiones considerando el marco. Si hay un rasgo o vano libre, reste 5 mm tanto en el ancho como en el alto de esa medida. Mida en 3 puntos distintos y proporcione la menor medida obtenida.',
      noteTitle: 'Cómo medir tu ventana',
      pageTitle: 'Ingresa las medidas de tu ventana',
      stepperTitleDesktop: 'Ingresa las medidas',
      stepperTitleMobile: 'Medidas',
      title: 'windows flow measurement page',
    },
    {
      contentTypeUid: 'journeys_page_product_details',
      backButtonText: 'Volver',
      installationConditions:
        '<ol><li>Para una correcta instalación de cualquier ventana o ventanal, es indispensable tener el Vano nivelado y aplomado. Además debe estar libre o con desinstalación de ventanas existente de aluminio o PVC (no se reparan vanos). Por favor considerar que el Lugar debe ser accesible para llegar con el producto.</li><li>En el valor de la instalación se Incluyen materiales, instalación de marco, de hojas, regulaciones y aplicación de sellos de silicona neutra exterior e interior. Solo se consideran instalaciones en lugares de 1 o 2 pisos.</li><li>Para Cualquier condición fuera de lo mencionado se puede agrega como valor adicional una vez que se realice la visita de factibilidad técnica y rectificación de medidas.</li></ol>',
      installationDescription:
        'Te contactaremos en 48 hrs. hábiles para agendar una visita y rectificar las medidas.',
      installationTitle: 'Quiero agregar la instalación de las ventanas',
      isStandalonePage: false,
      nextButtonText: 'Continuar',
      noInstallationDescription:
        'Utilizaremos las medidas ingresadas anteriormente.',
      noInstallationTitle: 'No quiero agregar instalación',
      pageTitle:
        '<p>Agrega instalación</p><p>Las opciones que estás viendo son <strong>específicas para la comuna que seleccionaste</strong>.</p>',
      stepperTitleDesktop: 'Agrega o no instalación',
      stepperTitleMobile: 'Instalación',
      title: 'windows flow installation and product details page',
    },
    {
      contentTypeUid: 'journeys_page_addtocart',
      backButtonText: 'Volver',
      isStandalonePage: false,
      nextButtonText: 'Agregar al carro',
      pageTitle: 'Resumen y agregar al carro',
      stepperTitleDesktop: 'Revisa y agrega al carro',
      stepperTitleMobile: 'Carro',
      title: 'Windows Flow add to cart page',
      cartUrl: 'https://www.sodimac.cl/sodimac-cl/cart',
      isCartPageOpenInNewTab: false,
      productType: 'Ventana',
      attributeMapping: [],
    },
  ] as Page[],
  title: 'Journeys Windows Flow - test',
};
