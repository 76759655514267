import { useContext, useEffect, useState } from 'react';
import { ProjectedValue, Timeline, TimelinePage } from './timeline.model';
import { TimeLineContext } from '../../providers/timeline';
import { getFacetsData } from '../../services/products.service';
import { API_STATUS } from '../../utils/constants';
import { AppContext } from '../../providers';
import { get } from 'lodash';
import {
  getAdjustedIndex,
  getFilteredFacetValues,
  getIsSelectedStatus,
  getLatestSelectedOptions,
  getMainImageData,
  getNextSingleValueStepsCount,
  getPreviousSingleValueStepsCount,
  getSubStepEndIndex,
  resetCurrentStepProjectedValues,
  updateIsSelectedInSubStepsData,
} from '../../utils/timelineHelper';
import { ERROR_MESSAGES } from './constants';
import { getCurrentTenant } from '../../utils';
import { ApiStatusType } from '../../utils/common.model';
import useResetState from '../../hooks/useResetState';
import { PAGE_NAMES } from '../page-wrapper/constants';

const useTimeline = (data: TimelinePage) => {
  const { subSteps, updateSubSteps, currentSubStep, updateCurrentSubStep } =
    useContext(TimeLineContext);
  const [selectionApiStatus, updateSelectionApiStatus] =
    useState<ApiStatusType>(API_STATUS.IDLE);

  const { appState, appActions } = useContext(AppContext);
  const { resetCurrentStepState } = useResetState();
  const { categoryId, stepper } = appState;
  const { currentStep } = stepper;
  const { timelines } = data;
  const tenant = getCurrentTenant();
  const selectedOptions = getLatestSelectedOptions(
    subSteps.slice(0, currentSubStep)
  );
  useEffect(() => {
    fetchFacetData();
  }, []);

  const handleError = (facetsData: Record<string, any>) => {
    const isError = get(facetsData, 'isError', false);
    if (isError) {
      updateSelectionApiStatus(API_STATUS.ERROR);
      if (facetsData.error === ERROR_MESSAGES.FACET_NOT_FOUNT) {
        // TODO: handle errors properly
      }
    }

    return isError;
  };

  const fetchFacetData = async () => {
    // If we have already fetched the data then no need to fetch it again
    // This will happen when we go back from next Pages
    if (subSteps.length > 0) {
      return;
    }
    updateSelectionApiStatus(API_STATUS.LOADING);
    // This is the first sub step
    const [{ facetAttributeName }] = timelines;
    const facetsData = await getFacetsData(
      tenant,
      categoryId,
      [facetAttributeName],
      selectedOptions
    );
    const isError = handleError(facetsData);
    if (isError) return;
    const { facets } = facetsData;
    const [csSubStep1Data] = timelines;
    const subStepData = getFilteredFacetValues(csSubStep1Data, facets);
    updateSubSteps([subStepData]);
    updateSelectionApiStatus(API_STATUS.SUCCESS);
  };

  const checkForNextSubStep = async (
    subStepNumber: number,
    selectedOptions: Record<string, string>,
    latestSubSteps: Timeline[]
  ) => {
    const nextSubStep = subStepNumber + 1;
    // Base case: if we've processed all sub-steps
    if (nextSubStep >= timelines.length) {
      updateSubSteps(latestSubSteps);
      updateSelectionApiStatus(API_STATUS.SUCCESS);
      return;
    }
    timelines[nextSubStep];
    const nextTimeline = get(timelines, nextSubStep);
    const facetAttributeName = get(nextTimeline, 'facetAttributeName', '');
    const facetsDataRes = await getFacetsData(
      tenant,
      categoryId,
      [facetAttributeName],
      selectedOptions
    );
    const isError = handleError(facetsDataRes);
    if (isError) return;
    const facetsData = facetsDataRes.facets || [];
    const subStepData = getFilteredFacetValues(nextTimeline, facetsData);
    const { projectedValues } = subStepData;
    const updatedSubSteps = [...latestSubSteps, subStepData];
    if (projectedValues.length === 1) {
      const [onlyAvailableValue] = projectedValues;
      const { facetValue } = onlyAvailableValue;
      const updatedSelectedOptions = {
        ...selectedOptions,
        [facetAttributeName]: facetValue,
      };
      const updatedProjectedValues = [
        {
          ...onlyAvailableValue,
          isSelected: true,
        },
      ];
      const updatedSubStepData = {
        ...subStepData,
        projectedValues: updatedProjectedValues,
      };
      const updatedSubStepsWithSelection = [
        ...latestSubSteps,
        updatedSubStepData,
      ];
      await checkForNextSubStep(
        nextSubStep,
        updatedSelectedOptions,
        updatedSubStepsWithSelection
      );
    } else {
      updateSubSteps(updatedSubSteps);
      updateSelectionApiStatus(API_STATUS.SUCCESS);
    }
  };

  const onCardClick = (selectedData: ProjectedValue) => {
    const { facetValue } = selectedData;
    const currentFacetAttributeName =
      subSteps[currentSubStep].facetAttributeName;

    const latestSelectedOptions = {
      ...selectedOptions,
      [currentFacetAttributeName]: facetValue,
    };

    const updatedSubSteps = updateIsSelectedInSubStepsData(
      subSteps.slice(0, currentSubStep + 1),
      currentFacetAttributeName,
      facetValue
    );

    updateSelectionApiStatus(API_STATUS.LOADING);
    checkForNextSubStep(currentSubStep, latestSelectedOptions, updatedSubSteps);
  };

  const goToNextPageOrSubStep = async () => {
    const nextSubStep =
      currentSubStep +
      1 +
      getNextSingleValueStepsCount(subSteps, currentSubStep);
    const totalSubSteps = timelines.length;
    if (nextSubStep >= totalSubSteps) {
      appActions.updateStepperInfo({ currentStep: currentStep + 1 });
    } else {
      updateCurrentSubStep(nextSubStep);
    }
  };

  const goToPreviousPageOrSubStep = () => {
    const previousSubStep =
      currentSubStep -
      getPreviousSingleValueStepsCount(subSteps, currentSubStep) -
      1;
    if (previousSubStep < 0) {
      appActions.updateStepperInfo({ currentStep: currentStep - 1 });
      resetCurrentStepState(PAGE_NAMES.JOURNEYS_PAGE_TIMELINE);
      return;
    }
    let latestSubSteps = subSteps.slice(0, currentSubStep + 1);
    latestSubSteps = resetCurrentStepProjectedValues(latestSubSteps);

    updateCurrentSubStep(previousSubStep);
    updateSubSteps(latestSubSteps);
  };

  const goToClickedSubStep = (index: number) => {
    if (index === currentSubStep) {
      return;
    }
    // if selected index substep has only one value then correct the index accordingly
    const adjustedIndex = getAdjustedIndex(subSteps, index);
    const endIndex = getSubStepEndIndex(subSteps, index);
    let latestSubSteps = subSteps.slice(0, endIndex + 1);
    latestSubSteps = resetCurrentStepProjectedValues(latestSubSteps);
    updateCurrentSubStep(adjustedIndex);
    updateSubSteps(latestSubSteps);
  };

  const subStepsWhichHaveOneValue = subSteps.filter(
    (subStep: any, index: number) => {
      return index > currentSubStep && subStep.projectedValues.length === 1;
    }
  );

  const {
    imageDesktopUrl,
    imageMobileUrl,
    imageAlt,
    imageContentTitle,
    imageDescription,
  } = getMainImageData(subSteps[currentSubStep]);
  const { nextButtonText, backButtonText } = data;
  const currentSubStepData = subSteps[currentSubStep];
  const isContinueButtonEnabled = getIsSelectedStatus(currentSubStepData);
  const isNextSubStepsHaveOnlyOneOption = subStepsWhichHaveOneValue.length > 0;
  const subStepperData = subSteps.filter(
    (item, index) => index <= currentSubStep
  );

  const {
    viewType,
    optionalTitle: optionalPageTitle,
    title: pageTitle,
  } = currentSubStepData || { viewType: '', optionalTitle: '', title: '' };
  const commonProps = {
    currentSubStep,
    subStepsWhichHaveOneValue,
    backButtonText,
    imageContentTitle,
    imageDescription,
    nextButtonText,
    isContinueButtonEnabled,
    isNextSubStepsHaveOnlyOneOption,
    subStepperData,
    optionalPageTitle,
    pageTitle,
    currentSubStepData,
    onCardClick,
    goToNextPageOrSubStep,
    goToPreviousPageOrSubStep,
    goToClickedSubStep,
  };

  const desktopProps = {
    ...commonProps,
    imageUrl: imageDesktopUrl,
    imageAlt,
    viewType,
  };

  const mobileProps = {
    ...commonProps,
    imageUrl: imageMobileUrl,
    imageAlt,
  };

  return {
    subSteps,
    selectionApiStatus,
    desktopProps,
    mobileProps,
  };
};

export default useTimeline;
