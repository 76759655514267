/* istanbul ignore file */
import { noop } from '../../utils';
import { TimelineContext } from './timeline-state.model';

export const timeLineInitialState: TimelineContext = {
  subSteps: [],
  updateSubSteps: noop,
  currentSubStep: 0,
  updateCurrentSubStep: noop,
  resetTimelineState: noop,
};
