import {
  getCurrentTenant,
  getMobileAppStatus,
  isAddToCartAtgEnabled,
  isSodimacBusinessUnit,
} from '../../utils';
import { AddToCartInfo } from '../models/addtocart.service.model';
import { MOBILE_APP_EVENTS } from '../../utils/constants';
import {
  addToCartCatalyst,
  getFACatalystCartLines,
} from './catalyst-addtocart.service';
import { atgAddToCart } from './atg-addtocart.service';
import { EXPERIENCE, TENANT_CROSS_CONFIG } from '../../utils/constants/urls';

export const handleMobileAppAddToCart = (productsData: AddToCartInfo) => {
  const isSodimacBU = isSodimacBusinessUnit();
  const isAtgEnabled = isAddToCartAtgEnabled();
  let cartData = {};
  if (isSodimacBU && isAtgEnabled) {
    cartData = productsData;
  } else {
    cartData = getFACatalystCartLines(productsData);
  }

  window.parent.dispatchEvent(
    new CustomEvent(MOBILE_APP_EVENTS.ADD_TO_CART, {
      detail: {
        cartData,
      },
    })
  );
};

export const addToCart = async (productsData: AddToCartInfo) => {
  let res = { success: false };
  try {
    const isMobileApp = getMobileAppStatus();
    if (isMobileApp) {
      handleMobileAppAddToCart(productsData);
      return;
    } 
    const tenant = getCurrentTenant();
    const {experience} = TENANT_CROSS_CONFIG[tenant];
    const addToCartStrategy = {
      [EXPERIENCE.catalyst]: addToCartCatalyst,
      [EXPERIENCE.atg]: atgAddToCart
    }
    const addToCart = addToCartStrategy[experience];
    res = await addToCart(productsData);
  } catch (error) {
    // TODO: handle error case
    res = { success: false };
  }

  return res || { success: false };
};
