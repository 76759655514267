import { ProjectedValue, Timeline } from '../../pages/timeline/timeline.model';
import {
  getPlaceholderDesktopMainImage,
  getPlaceHolderThumbNail,
} from '../../utils';
import {
  FunctionVoid,
  FunctionWith3Args,
  FunctionWithProp,
} from '../../utils/common.model';
import { IMAGE_TYPES } from '../../utils/constants';
import Image from '../image';
import {
  GridCard,
  GridCardImageWrapper,
  GridCardTitle,
  GridContainer,
} from './styles';

const GridCards: React.FC<{
  data: Timeline;
  onCardClick: FunctionWithProp<ProjectedValue>;
  handleOnMouseLeave: FunctionVoid;
  handleOnMouseEnter: FunctionWith3Args<string, string, string>;
}> = ({ data, onCardClick, handleOnMouseLeave, handleOnMouseEnter }) => {
  const { projectedValues } = data;
  const onMouseEnter =
    (mainImageUrl: string, label: string, descriptionOnImage: string) => () =>
      handleOnMouseEnter(mainImageUrl, label, descriptionOnImage);
  const onCardItemClick = (item: any) => () => onCardClick(item);

  return (
    <GridContainer>
      {projectedValues.map((item) => {
        const {
          label,
          imageDesktopSmall,
          imageDesktopMain,
          descriptionOnImage,
        } = item;
        const placeholderThumbNailUrl = getPlaceHolderThumbNail();
        const { url = placeholderThumbNailUrl, title } =
          imageDesktopSmall || {};
        const desktopMainImagePlaceholder = getPlaceholderDesktopMainImage();
        const { url: mainImageUrl = desktopMainImagePlaceholder } =
          imageDesktopMain || {};

        return (
          <GridCard
            key={label}
            onClick={onCardItemClick(item)}
            onMouseEnter={onMouseEnter(mainImageUrl, label, descriptionOnImage)}
            onMouseLeave={handleOnMouseLeave}
          >
            <GridCardImageWrapper>
              <Image
                src={url}
                alt={title}
                imageType={IMAGE_TYPES.THUMBNAIL}
                objectFit="cover"
                borderRadius="3px 3px 0 0"
              />
            </GridCardImageWrapper>
            <GridCardTitle>{label}</GridCardTitle>
          </GridCard>
        );
      })}
    </GridContainer>
  );
};

export default GridCards;
