import Loader from '../../components/loader';
import useDevice from '../../hooks/useDevice';
import { API_STATUS } from '../../utils/constants';
import { Page } from '../pages.model';
import TimeLineDesktop from './desktop';
import TimelineMobile from './mobile';
import { TimelinePage } from './timeline.model';
import useTimeline from './useTimeline';

const Timeline: React.FC<{ data: Page }> = ({ data }) => {
  const pageData = data as TimelinePage;
  const { selectionApiStatus, subSteps, desktopProps, mobileProps } =
    useTimeline(pageData);
  const { isDesktop } = useDevice();

  if (selectionApiStatus === API_STATUS.ERROR) {
    return <div>Error Loading Data...</div>;
  }
  // TODO: Add Error component

  return (
    <>
      {selectionApiStatus === API_STATUS.LOADING && <Loader />}
      {isDesktop && subSteps.length > 0 && (
        <TimeLineDesktop {...desktopProps} />
      )}
      {!isDesktop && subSteps.length > 0 && <TimelineMobile {...mobileProps} />}
    </>
  );
};

export default Timeline;
