import { getSelectedFacetValue } from '../../utils/timelineHelper';
import { Timeline } from '../timeline/timeline.model';

export const getTimelineViewSelectedOptions = (subStepsData: Timeline[]) => {
  if (!subStepsData) {
    return {};
  }
  return subStepsData.reduce((acc, subStep) => {
    const selectedValue = getSelectedFacetValue(subStep.projectedValues);
    const label = subStep?.label;
    if (!selectedValue || !label) {
      return acc;
    }
    return {
      ...acc,
      [label]: selectedValue,
    };
  }, {});
};
