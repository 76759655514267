import React from 'react';
import { IMAGE_FORMATS, IMAGE_WIDTHS } from '../../utils/constants';
import { updateImageURL } from '../../utils';
import { ImageEl } from './styles';

const Image: React.FC<{
  src: string;
  alt: string;
  imageType: string;
  objectFit?: 'cover' | 'contain' | 'fill' | 'none' | 'scale-down';
  borderRadius?: string;
  dataTestId?: string;
}> = ({
  src,
  alt,
  imageType,
  objectFit = 'contain',
  borderRadius = '0',
  dataTestId,
}) => {
  const commonOptions = {
    url: src,
    width: IMAGE_WIDTHS[imageType],
  };
  const webpOptions = {
    ...commonOptions,
    format: IMAGE_FORMATS.WEBP,
  };
  const jpegOptions = {
    ...commonOptions,
    format: IMAGE_FORMATS.JPEG,
  };
  const webpUrl = updateImageURL(webpOptions);
  const jpegUrl = updateImageURL(jpegOptions);

  return (
    <picture data-testid={dataTestId}>
      <source srcSet={webpUrl} type="image/webp" />
      <ImageEl
        src={jpegUrl}
        alt={alt}
        objectFit={objectFit}
        borderRadius={borderRadius}
      />
    </picture>
  );
};

export default Image;
