import {
  AddToCartInfo,
  SessionInfoResponse,
} from '../models/addtocart.service.model';
import { getCurrentAppEnvironment, getCurrentTenant } from '../../utils';
import {
  ATG_CART_URL,
  ATG_SITE_IDS,
  FETCH_SESSION_INFO_URL,
  TENANTS,
} from '../../utils/constants/urls';

export const getFetchSessionInfoUrl = () => {
  const env = getCurrentAppEnvironment();
  const tenant = getCurrentTenant();
  const baseUrl = TENANTS[tenant][env].BASE_URL;
  const url = `${baseUrl}/${FETCH_SESSION_INFO_URL}`;

  return url;
};

export const getAddToCartAtgUrl = () => {
  const env = getCurrentAppEnvironment();
  const tenant = getCurrentTenant();
  const baseUrl = TENANTS[tenant][env].BASE_URL;
  const siteId = ATG_SITE_IDS[tenant];
  const url = `${baseUrl}/${ATG_CART_URL}?pushSite=${siteId}`;

  return url;
};

export const atgAddToCart = async (productsData: AddToCartInfo) => {
  try {
    const sessionResponse = await fetch(getFetchSessionInfoUrl(), {
      credentials: 'include',
    });
    const sessionInfo = (await sessionResponse.json()) as SessionInfoResponse;
    const {
      state: { sessionConfirmationNumber = '' },
    } = sessionInfo;
    if (!sessionConfirmationNumber) {
      return { success: false };
    }
    const addToCartRequestBody = {
      sessionConfirmationNumber,
      formSubmissionData: [{
        productId: productsData.productId,
        skuId: productsData.skuId,
        quantity: productsData.quantity,
      }],
    };
    const cartResponse = await fetch(getAddToCartAtgUrl(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(addToCartRequestBody),
      credentials: 'include',
    });
    const cartInfo = await cartResponse.json();
    return cartInfo;
  } catch (error) {
    return { success: false };
  }
};
