import React from 'react';
import VerticalStepper from '../../../components/vertical-stepper';
import { PageContainer } from './styles';
import { TimelinePageDesktopProps } from '../timeline.model';
import { PageContentWrapper } from './PageContentWrapper';

const TimeLineDesktop: React.FC<TimelinePageDesktopProps> = ({
  subStepperData,
  currentSubStep,
  onCardClick,
  goToNextPageOrSubStep,
  goToPreviousPageOrSubStep,
  subStepsWhichHaveOneValue,
  imageUrl,
  imageAlt,
  imageContentTitle,
  imageDescription,
  nextButtonText,
  backButtonText,
  goToClickedSubStep,
  viewType,
  isContinueButtonEnabled,
  currentSubStepData,
  pageTitle,
  optionalPageTitle,
  isNextSubStepsHaveOnlyOneOption,
}) => {
  return (
    <PageContainer data-testid="desktop-timeline-page">
      <VerticalStepper
        data={subStepperData}
        currentSubStep={currentSubStep}
        goToClickedSubStep={goToClickedSubStep}
      />
      <PageContentWrapper
        currentSubStep={currentSubStep}
        onCardClick={onCardClick}
        goToNextPageOrSubStep={goToNextPageOrSubStep}
        goToPreviousPageOrSubStep={goToPreviousPageOrSubStep}
        subStepsWhichHaveOneValue={subStepsWhichHaveOneValue}
        imageUrl={imageUrl}
        imageAlt={imageAlt}
        imageContentTitle={imageContentTitle}
        imageDescription={imageDescription}
        nextButtonText={nextButtonText}
        backButtonText={backButtonText}
        goToClickedSubStep={goToClickedSubStep}
        viewType={viewType}
        isContinueButtonEnabled={isContinueButtonEnabled}
        currentSubStepData={currentSubStepData}
        pageTitle={pageTitle}
        optionalPageTitle={optionalPageTitle}
        isNextSubStepsHaveOnlyOneOption={isNextSubStepsHaveOnlyOneOption}
      />
    </PageContainer>
  );
};

export default TimeLineDesktop;
