import {
  AddToCartInfo,
  CartLine,
  CartLineSO,
} from '../models/addtocart.service.model';
import {
  getAuthSid,
  getCartId,
  getCurrentAppEnvironment,
  getCurrentTenant,
  getPoliticalId,
  getPriceGroupId,
  getZones,
  setCartIdCookie,
} from '../../utils';
import {
  CATALYST_CART_URL,
  TENANT_CROSS_CONFIG,
  TENANTS,
} from '../../utils/constants/urls';
import { BUSINESS_UNITS } from '../../utils/constants';
import { get } from 'lodash';

export const getCustomInfo = (
  rawCustomInfo: AddToCartInfo['customInformation'],
  productId: string,
  sellerSkuId: string
) => {
  const { journeysType, attributeMapping, selectedSpecs } = rawCustomInfo;
  const group = `journey-${journeysType.toLowerCase()}`;
  const selectedOptions = attributeMapping.map((item) => {
    const { customInfoName, productAttributeName } = item;
    const value = selectedSpecs[productAttributeName];
    return {
      name: customInfoName,
      values: [value],
      group,
    };
  });

  const customInfo = [
    {
      name: 'journeyType',
      values: [journeysType],
      group: 'journey',
    },
    {
      name: 'sku_base',
      values: [productId],
      group,
    },
    {
      name: 'seller-sku',
      values: [sellerSkuId],
      group,
    },
    ...selectedOptions,
  ];

  return customInfo;
};

export const getFACatalystCartLines = (data: AddToCartInfo): CartLine[] => {
  const { skuId, productId, quantity, customInformation, sellerSkuId } = data;
  const cartLines = {
    cartLineNumber: skuId,
    // cartline number will be variantid
    item: {
      variantId: skuId,
      offeringId: productId,
    },
    quantity: {
      quantityNumber: quantity,
      quantityUnit: 'C/U',
    },
    customInfo: getCustomInfo(customInformation, productId, sellerSkuId),
  };

  return [cartLines];
};

export const getSOCatalystCartLines = (data: AddToCartInfo): CartLineSO[] => {
  const tenant = getCurrentTenant();
  const { quantityUnit } = TENANT_CROSS_CONFIG[tenant];
  const { skuId, quantity } = data;
  const cartLines = {
    cartLineNumber: skuId,
    compatibilityGroup: 0,
    item: {
      variantId: skuId,
    },
    quantity: {
      quantityNumber: quantity,
      quantityUnit: `${quantityUnit}`,
    },
    priceGroup: getPriceGroupId(),
  };

  return [cartLines];
};

export const getAddToCartFAUrl = () => {
  const env = getCurrentAppEnvironment();
  const tenant = getCurrentTenant();
  const cartId = getCartId();
  const baseUrl = TENANTS[tenant][env].BASE_URL;
  const url = new URL(`${baseUrl}/${CATALYST_CART_URL}`);
  if (cartId) {
    url.searchParams.append('cartId', cartId);
  }

  return url.toString();
};

export const getRequestBodyFA = (data: AddToCartInfo) => {
  const cartLines = getFACatalystCartLines(data);
  const politicalAreaId = getPoliticalId();
  const priceGroup = getPriceGroupId();
  const requestBody = {
    data: {
      cart: {
        cartLines,
      },
    },
    metadata: {
      politicalAreaId,
      priceGroup,
    },
  };

  return requestBody;
};

export const getRequestBodySO = (data: AddToCartInfo) => {
  const cartLines = getSOCatalystCartLines(data);
  const requestBody = {
    data: {
      cart: {
        cartLines,
      },
    },
    metadata: {
      zoneId: getZones(),
      priceGroup: getPriceGroupId(),
    },
  };

  return requestBody;
};

export const addToCartCatalyst = async (productData: AddToCartInfo) => {
  try {
    const url = getAddToCartFAUrl();
    const tenant = getCurrentTenant();
    const tenantConfig: Record<string, string | undefined> =
      TENANT_CROSS_CONFIG[tenant];
    const catalystHeaders: string[] = [
      'commerce',
      'ecommName',
      'originName',
      'originView',
      'siteName',
    ];
    const allowedTenantHeaders = catalystHeaders
      .filter((headerName) => tenantConfig[headerName])
      .reduce(
        (acc, headerName) => ({
          ...acc,
          [headerName]: tenantConfig[headerName],
        }),
        {}
      );
    const headers: Record<string, string> = {
      ...allowedTenantHeaders,
      'content-type': 'application/json',
      'x-channel-id': 'WEB',
    };
    const authSid = getAuthSid();
    if (authSid) {
      const authorizationName = get(
        tenantConfig,
        'authorization',
        ''
      ) as string;
      headers[authorizationName] = authSid;
    }
    const requestPayloadStrategy: Record<string, any> = {
      [BUSINESS_UNITS.FALABELLA]: getRequestBodyFA,
      [BUSINESS_UNITS.SODIMAC]: getRequestBodySO,
    };
    const cartLineName = get(
      tenantConfig,
      'cartLineName',
      BUSINESS_UNITS.FALABELLA
    ) as string;
    const payload = requestPayloadStrategy[cartLineName](productData);
    const response = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(payload),
      credentials: 'include',
    });
    if (!response.ok) {
      return { success: false };
    }
    const jsonRes = await response.json();
    const cartAlerts = jsonRes?.data?.cart?.cartAlerts || [];
    const responseCartId = jsonRes?.data?.cart?.cartId;
    setCartIdCookie(responseCartId);
    return { success: cartAlerts.length === 0 };
  } catch (error) {
    return { success: false };
  }
};
