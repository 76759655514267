import styled from 'styled-components';
import { COLORS, FONT_SIZES } from '../../../utils/constants/styles';
import { mediaQuery } from '../../../utils/media-query';

export const DefaultListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 16px;
  background-color: ${COLORS.NEUTRAL_GRAY_LIGHT};
  box-sizing: border-box;

  ${mediaQuery.desktopToUp(`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    background-color: ${COLORS.NEUTRAL_GRAY_LIGHT};
    width: 100%;
  `)}
`;

export const Title = styled.div`
  font-size: ${FONT_SIZES.MOBILE_HEADING};
  font-weight: 700;
  line-height: 20px;
  color: ${COLORS.DARK_GRAY};
`;

export const DefaultListCardsContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  width: 100%;
`;

export const DefaultListCard = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  flex-grow: 1;
`;

export const DefaultListCardImage = styled.img`
  width: 140px;
  height: 100px;
  object-fit: cover;
`;

export const DefaultListCardContent = styled.div`
  display: flex;
  flex-direction: column;
  color: ${COLORS.DARK_GRAY};
  font-size: ${FONT_SIZES.REGULAR};
  line-height: 20px;
  padding-right: 16px;
`;

export const DefaultListCardTitle = styled.div`
  font-weight: 700;
`;

export const DefaultListCardValue = styled.div``;
