import {
  getPlaceholderDesktopMainImage,
  getPlaceholderMobileMainImage,
  removeSpacesAndLowerCase,
} from '.';
import {
  SelectionApiResponseModel,
  Timeline,
} from '../pages/timeline/timeline.model';

// csFacetData - content stack facet data
// coreFacetsData - data from backend
export const getFilteredFacetValues = (
  csFacetData: Timeline,
  coreFacetsData: SelectionApiResponseModel['facets']
) => {
  if (!csFacetData || !coreFacetsData || !coreFacetsData.length) {
    return { ...csFacetData, projectedValues: [] };
  }
  const csFacetLabel = csFacetData?.facetAttributeName?.toLowerCase() || '';

  if (!csFacetLabel) {
    return { ...csFacetData, projectedValues: [] };
  }

  const matchedCoreFacet = coreFacetsData.find((coreFacet) => {
    const coreFacetLabel = coreFacet?.label?.toLowerCase() || '';
    return coreFacetLabel === csFacetLabel;
  }) || { values: [] };

  const coreFacetValuesSet = new Set(
    matchedCoreFacet.values.map((coreFacet) =>
      removeSpacesAndLowerCase(coreFacet.label)
    )
  );

  const filteredData = {
    ...csFacetData,
    projectedValues: csFacetData.projectedValues.filter((projectedValue) =>
      coreFacetValuesSet.has(
        removeSpacesAndLowerCase(projectedValue.facetValue)
      )
    ),
  };

  return filteredData;
};

export const getSelectedFacetValue = (values: Timeline['projectedValues']) => {
  if (!values || !values.length) {
    return '';
  }
  const selectedFacetData = values.find((value) => {
    return value.isSelected || false;
  });

  return selectedFacetData?.facetValue || '';
};

export const getIsSelectedStatus = (data: Timeline) => {
  if (!data || !data.projectedValues || !data.projectedValues.length) {
    return false;
  }
  const isSelected = data.projectedValues.some((value) => {
    return value.isSelected || false;
  });

  return isSelected;
};

export const updateIsSelectedInSubStepsData = (
  subSteps: Timeline[],
  facetAttributeName: string,
  facetValue: string
) => {
  if (!facetAttributeName || !facetValue) {
    return subSteps;
  }
  return (
    subSteps?.map((subStep) => {
      if (
        subStep.facetAttributeName.toLowerCase() ===
        facetAttributeName.toLowerCase()
      ) {
        return {
          ...subStep,
          projectedValues:
            subStep?.projectedValues.map((projectedValue) => ({
              ...projectedValue,
              isSelected:
                removeSpacesAndLowerCase(projectedValue.facetValue) ===
                removeSpacesAndLowerCase(facetValue),
            })) || [],
        };
      }
      return subStep;
    }) || []
  );
};

export const getMainImageData = (data: Timeline) => {
  const desktopMainImagePlaceHolder = getPlaceholderDesktopMainImage();
  const mobileMainImagePlaceHolder = getPlaceholderMobileMainImage();

  if (!data) {
    return {
      imageDesktopUrl: desktopMainImagePlaceHolder,
      imageMobileUrl: mobileMainImagePlaceHolder,
      imageAlt: '',
      imageContentTitle: '',
      imageDescription: '',
    };
  }

  const {
    defaultImageDesktop,
    defaultImageMobile,
    projectedValues = [],
  } = data;

  const defaultDesktopImageUrl = defaultImageDesktop?.url || '';
  const defaultImageAlt = defaultImageDesktop?.title || '';
  const defaultMobileImageUrl = defaultImageMobile?.url || '';

  const selectedValue =
    projectedValues.find((value) => value.isSelected) ||
    ({} as Timeline['projectedValues'][0]);

  const {
    imageDesktopMain,
    imageMobile,
    label = '',
    descriptionOnImage = '',
  } = selectedValue;

  const desktopUrl =
    imageDesktopMain?.url ||
    defaultDesktopImageUrl ||
    desktopMainImagePlaceHolder;
  const imageAlt = imageDesktopMain?.title || defaultImageAlt;
  const mobileUrl =
    imageMobile?.url || defaultMobileImageUrl || mobileMainImagePlaceHolder;

  return {
    imageDesktopUrl: desktopUrl,
    imageMobileUrl: mobileUrl,
    imageAlt: imageAlt,
    imageContentTitle: label,
    imageDescription: descriptionOnImage,
  };
};

export const getNextSingleValueStepsCount = (
  subSteps: Timeline[],
  currentSubStep: number
) => {
  let singleValueStepsCount = 0;
  for (let i = currentSubStep + 1; i < subSteps?.length; i++) {
    const subStep = subSteps[i];
    if (subStep?.projectedValues?.length === 1) {
      singleValueStepsCount++;
    } else {
      break;
    }
  }

  return singleValueStepsCount;
};

export const getPreviousSingleValueStepsCount = (
  subSteps: Timeline[],
  currentSubStep: number
) => {
  if (!subSteps || !subSteps.length) {
    return 0;
  }
  let singleValueStepsCount = 0;
  for (let i = currentSubStep - 1; i >= 0; i--) {
    const subStep = subSteps[i];
    if (subStep?.projectedValues?.length === 1) {
      singleValueStepsCount++;
    } else {
      break;
    }
  }

  return singleValueStepsCount;
};

export const getAdjustedIndex = (
  subSteps: Timeline[],
  clickedIndex: number
) => {
  if (!subSteps || !subSteps.length) {
    return 0;
  }
  let adjustedIndex = clickedIndex;
  for (let i = clickedIndex; i >= 0; i--) {
    const subStep = subSteps[i];
    if (subStep?.projectedValues?.length === 1) {
      adjustedIndex--;
    } else {
      break;
    }
  }
  adjustedIndex = adjustedIndex < 0 ? 0 : adjustedIndex;

  return adjustedIndex;
};

export const getSubStepEndIndex = (
  subSteps: Timeline[],
  clickedIndex: number
) => {
  if (!subSteps || !subSteps.length) {
    return 0;
  }
  let adjustedIndex = clickedIndex;
  for (let i = clickedIndex + 1; i < subSteps.length; i++) {
    const subStep = subSteps[i];
    adjustedIndex++;
    if (subStep?.projectedValues?.length > 1) {
      break;
    }
  }

  return adjustedIndex;
};

export const getLatestSelectedOptions = (subStepsData: Timeline[]) => {
  if (!subStepsData) {
    return {};
  }
  return subStepsData.reduce((acc, subStep) => {
    const selectedValue = getSelectedFacetValue(subStep.projectedValues);
    const facetAttributeName = subStep?.facetAttributeName;
    if (!selectedValue || !facetAttributeName) {
      return acc;
    }
    return {
      ...acc,
      [facetAttributeName]: selectedValue,
    };
  }, {});
};

export const resetCurrentStepProjectedValues = (subStepsData: Timeline[]) => {
  if (subStepsData.length === 0) return subStepsData;

  const newArray = [...subStepsData];
  const lastObject = newArray[newArray.length - 1];

  const updatedLastObject = {
    ...lastObject,
    projectedValues:
      lastObject?.projectedValues?.map((projectedValue) => ({
        ...projectedValue,
        isSelected: false,
      })) || [],
  };

  newArray[newArray.length - 1] = updatedLastObject;

  return newArray;
};
