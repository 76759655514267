import { ENVS } from '../utils/constants';
import {
  Environments,
  HOME_IMPROVEMENT_SERVICE_BASE_URL,
  SELECTION_API_PATH,
} from '../utils/constants/urls';
import { getZones } from '../utils';

// Cache to store fetched facets data
export const facetsDataCache = new Map<string, any>();

export const getSelectionApiUrl = (
  tenant: string,
  zones: string,
  categoryId: string,
  facetFields: string[],
  selectedOptions: Record<string, string>
): string => {
  const env = ENVS.includes(process.env.REACT_APP_ENVIRONMENT as Environments)
    ? (process.env.REACT_APP_ENVIRONMENT as Environments)
    : 'production';

  const lowerCasedTenant = tenant.toLowerCase();
  const country = tenant.substring(0, 2).toUpperCase() as 'SO' | 'FA';

  const baseUrl = HOME_IMPROVEMENT_SERVICE_BASE_URL[country][env];
  const url = new URL(`${SELECTION_API_PATH}/${lowerCasedTenant}`, baseUrl);

  const params = url.searchParams;
  params.set('categoryId', categoryId);

  if (zones) {
    params.set('zone', zones);
  }

  if (facetFields.length > 0) {
    params.set('facetFields', facetFields.join(','));
  }

  if (Object.keys(selectedOptions).length > 0) {
    const optionsString = Object.entries(selectedOptions)
      .map(([key, value]) => `${key}=${value}`)
      .join(',');
    params.set('selectedOptions', optionsString);
  }

  return url.toString();
};

export const getFacetsData = async (
  tenant: string,
  categoryId: string,
  facetFields: string[],
  selectedOptions: Record<string, string>
): Promise<any> => {
  let facetsData = {
    isError: true,
  };
  try {
    const zones = getZones();
    const lowerCasedTenant = tenant.toLowerCase();

    const url = getSelectionApiUrl(
      lowerCasedTenant,
      zones,
      categoryId,
      facetFields,
      selectedOptions
    );

    // Return cached data if available
    if (facetsDataCache.has(url)) {
      return facetsDataCache.get(url);
    }

    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }
    const facetsDataJson = await response.json();
    facetsData = {
      ...facetsData,
      ...facetsDataJson,
      status: response.status,
      isError: false,
    };
    // Store the fetched data in the cache
    facetsDataCache.set(url, facetsData);

    return facetsData;
  } catch (error) {
    // TODO: Handle errors properly
    return facetsData;
  }
};
