import {
  DefaultListWrapper,
  ImageContent,
  ImageDescription,
  ImageTitle,
  ImageWrapper,
  NextAndBackButtonsWrapper,
  PageContent,
  PageContentContainer,
  PageOptionalTitle,
  PageTitle,
} from './styles';
import GridCards from '../../../../components/grid-cards';
import ListCards from '../../../../components/list-cards';
import { IMAGE_TYPES, VIEW_TYPES } from '../../../../utils/constants';
import DefaultList from '../../../../components/default-list';
import NextAndBackButtons from '../../../../components/next-and-back-buttons';
import usePageContentWrapper from './usePageContentWrapper';
import { ProjectedValue, Timeline } from '../../timeline.model';
import { FunctionWithProp } from '../../../../utils/common.model';
import Image from '../../../../components/image';

export const PageContentWrapper: React.FC<{
  currentSubStep: number;
  onCardClick: FunctionWithProp<ProjectedValue>;
  goToNextPageOrSubStep: () => void;
  goToPreviousPageOrSubStep: () => void;
  subStepsWhichHaveOneValue: Timeline[];
  imageUrl: string;
  imageAlt: string;
  imageContentTitle: string;
  imageDescription: string;
  nextButtonText: string;
  backButtonText: string;
  goToClickedSubStep: FunctionWithProp<number>;
  viewType: string;
  isContinueButtonEnabled: boolean;
  currentSubStepData: Timeline;
  pageTitle: string;
  optionalPageTitle?: string;
  isNextSubStepsHaveOnlyOneOption: boolean;
}> = ({
  onCardClick,
  goToNextPageOrSubStep,
  goToPreviousPageOrSubStep,
  subStepsWhichHaveOneValue,
  imageUrl,
  imageAlt,
  imageContentTitle,
  imageDescription,
  nextButtonText,
  backButtonText,
  viewType,
  isContinueButtonEnabled,
  currentSubStepData,
  pageTitle,
  optionalPageTitle,
  isNextSubStepsHaveOnlyOneOption,
}) => {
  const {
    mainImageUrl,
    imageDesc,
    imageTitle,
    handleOnMouseEnter,
    handleOnMouseLeave,
  } = usePageContentWrapper({
    imageUrl,
    imageContentTitle,
    imageDescription,
  });

  return (
    <PageContentContainer>
      <PageContent>
        {optionalPageTitle && (
          <PageOptionalTitle>{optionalPageTitle}</PageOptionalTitle>
        )}
        {pageTitle && <PageTitle>{pageTitle}</PageTitle>}
        {viewType === VIEW_TYPES.GRID && (
          <GridCards
            data={currentSubStepData}
            onCardClick={onCardClick}
            handleOnMouseEnter={handleOnMouseEnter}
            handleOnMouseLeave={handleOnMouseLeave}
          ></GridCards>
        )}
        {viewType === VIEW_TYPES.LIST && (
          <ListCards
            data={currentSubStepData}
            onCardClick={onCardClick}
            handleOnMouseEnter={handleOnMouseEnter}
            handleOnMouseLeave={handleOnMouseLeave}
          ></ListCards>
        )}
        {isNextSubStepsHaveOnlyOneOption && (
          <DefaultListWrapper>
            <DefaultList data={subStepsWhichHaveOneValue}></DefaultList>
          </DefaultListWrapper>
        )}
        <NextAndBackButtonsWrapper>
          <NextAndBackButtons
            isIconVisible={false}
            isDisabled={!isContinueButtonEnabled}
            goToNextPageFn={goToNextPageOrSubStep}
            goToPreviousPageFn={goToPreviousPageOrSubStep}
            useCustomFunctions={true}
            nextButtonText={nextButtonText}
            backButtonText={backButtonText}
          />
        </NextAndBackButtonsWrapper>
      </PageContent>
      <ImageWrapper>
        <Image
          dataTestId="desktop-main-image"
          src={mainImageUrl}
          alt={imageAlt}
          imageType={IMAGE_TYPES.DESKTOP_MAIN}
        />
        {imageTitle && imageDesc && (
          <ImageContent>
            <ImageTitle>{imageTitle}</ImageTitle>
            <ImageDescription>{imageDesc}</ImageDescription>
          </ImageContent>
        )}
      </ImageWrapper>
    </PageContentContainer>
  );
};
