import { SwiperContainer, SwiperInner, SwiperItem } from './styles';
import { ProjectedValue, Timeline } from '../../pages/timeline/timeline.model';
import { FunctionWithProp } from '../../utils/common.model';
import useHorizontalScrollVisibility from '../../hooks/useHorizontalScrollVisibility';
import { CLASSES_LIST } from '../../utils/constants';
import { BlurLeft, BlurRight } from '../../utils/styles';

const Swipper: React.FC<{
  data: Timeline;
  onCardClick: FunctionWithProp<ProjectedValue>;
}> = ({ data, onCardClick }) => {
  const {
    isBlurLeftVisible,
    isBlurRightVisible,
    innerContainerRef,
    handleOnScroll,
  } = useHorizontalScrollVisibility();

  const { projectedValues = [] } = data;

  return (
    <SwiperContainer>
      <SwiperInner onScroll={handleOnScroll} ref={innerContainerRef}>
        {projectedValues.map((item) => {
          const isActive = item.isSelected ? CLASSES_LIST.ACTIVE : '';
          const { label = '' } = item;
          const handleOnClick = () => {
            onCardClick(item);
          };

          return (
            <SwiperItem
              key={label}
              className={isActive}
              onClick={handleOnClick}
              role="button"
            >
              {label}
            </SwiperItem>
          );
        })}
      </SwiperInner>

      {isBlurLeftVisible && <BlurLeft />}
      {isBlurRightVisible && <BlurRight />}
    </SwiperContainer>
  );
};

export default Swipper;
