import styled from 'styled-components';
import { COLORS, FONT_SIZES, ZINDEX } from '../../../../utils/constants/styles';

const imageWidth = 288;
const imageHeight = 202;
const imageContentWidth = 272;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 32px;
  background-color: ${COLORS.WHITE};
`;

export const TitlesContainer = styled.div`
  color: ${COLORS.DARK_GRAY};
  margin-top: 16px;
`;

export const PageTitle = styled.div`
  font-size: ${FONT_SIZES.REGULAR};
  line-height: 20px;
  margin-bottom: 2px;
`;

export const PageSecondaryTitle = styled.div`
  font-size: ${FONT_SIZES.MOBILE_HEADING};
  margin-bottom: 8px;
  line-height: 20px;
  font-weight: 700;
`;

export const ImageContainer = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ImageWrapper = styled.div`
  width: ${imageWidth}px;
  height: ${imageHeight}px;

  @media (min-width: 400px) {
    width: calc(${imageWidth}px + 50px);
    height: calc((${imageWidth} + 50) / ${imageWidth}*${imageHeight}px);
  }

  @media (min-width: 500px) {
    width: calc(${imageWidth}px + 100px);
    height: calc((${imageWidth} + 100) / ${imageWidth}*${imageHeight}px);
  }
`;

export const ImageContent = styled.div`
  color: ${COLORS.DARK_GRAY};
  padding: 8px 16px 16px;
  background-color: ${COLORS.WHITE};
  border-radius: 4px;
  z-index: ${ZINDEX.LEVEL_1};
  position: relative;
  box-sizing: border-box;
  margin-top: -16px;
  width: ${imageContentWidth}px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  @media (min-width: 400px) {
    width: calc(${imageContentWidth}px + 50px);
  }

  @media (min-width: 500px) {
    width: calc(${imageContentWidth}px + 100px);
  }
`;

export const ImageTitle = styled.div`
  font-size: ${FONT_SIZES.MOBILE_HEADING};
  line-height: 24px;
  font-weight: 700;
`;

export const ImageDescription = styled.div`
  font-size: ${FONT_SIZES.SMALL};
  line-height: 16px;
`;

export const DefaultListWrapper = styled.div`
  margin-top: 24px;
`;

export const NextAndPreviousButtonsContainer = styled.div`
  margin-top: 24px;
`;
