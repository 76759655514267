import styled from 'styled-components';
import { COLORS, FONT_SIZES } from '../../../utils/constants/styles';

interface ListProps {
  isDescriptionAvailable: boolean;
}

export const ListContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  width: 100%;
`;

export const ListCard = styled.div<ListProps>`
  display: flex;
  background-color: ${COLORS.NEUTRAL_GRAY_LIGHT};
  box-shadow: 0 1px 1.5px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  align-items: center;
  cursor: pointer;
  flex-grow: 1;
  overflow-wrap: break-word;
  flex-basis: ${({ isDescriptionAvailable }) =>
    isDescriptionAvailable ? '100%' : 'calc(50% - 16px);'};
  &:hover {
    background-color: ${COLORS.GRAY_DARK};
    transform: scale(1.05);
  }

  &.active {
    background-color: ${COLORS.GRAY_DARK};
  }
`;

export const ListCardImageWrapper = styled.div`
  height: 100%;
  max-width: 140px;
  max-height: 100px;
`;

export const ListCardContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
`;

export const ListCardTitle = styled.div`
  color: ${COLORS.DARK_GRAY};
  font-size: ${FONT_SIZES.MOBILE_HEADING};
  line-height: 24px;
`;

export const ListCardDescription = styled.div`
  color: ${COLORS.DARK_GRAY};
  font-size: ${FONT_SIZES.REGULAR};
  line-height: 20px;
`;
