import { Timeline } from '../../pages/timeline/timeline.model';
import { FunctionWithProp } from '../../utils/common.model';
import { getSelectedFacetValue } from '../../utils/timelineHelper';
import {
  Circle,
  Label,
  LabelContainer,
  Line,
  TimeLine,
  TimeLineContainer,
  Value,
  VerticalStepperContainer,
} from './styles';

const VerticalStepper: React.FC<{
  data: Timeline[];
  currentSubStep: number;
  goToClickedSubStep: FunctionWithProp<number>;
}> = ({ data, currentSubStep, goToClickedSubStep }) => {
  const render = () => {
    return data.map((item: Timeline, index: number) => {
      const selectedValue = getSelectedFacetValue(item.projectedValues);
      const isCurrentStep = index === currentSubStep;
      const currentStepClass = isCurrentStep ? 'current' : '';
      const onClick = () => {
        if (isCurrentStep) {
          return;
        }
        goToClickedSubStep(index);
      };
      return (
        <TimeLineContainer key={item.label}>
          <TimeLine>
            <Circle className={currentStepClass} onClick={onClick}></Circle>
            {index === data.length - 1 ? null : <Line></Line>}
          </TimeLine>
          <LabelContainer onClick={onClick} className={currentStepClass}>
            <Label className={currentStepClass}>{item.label}</Label>
            <Value>{selectedValue}</Value>
          </LabelContainer>
        </TimeLineContainer>
      );
    });
  };
  return <VerticalStepperContainer>{render()}</VerticalStepperContainer>;
};

export default VerticalStepper;
