import { Timeline } from '../../pages/timeline/timeline.model';
import { getLanguage, getPlaceHolderThumbNail } from '../../utils';
import { TRANSLATIONS } from '../../utils/constants';
import {
  DefaultListCard,
  DefaultListCardContent,
  DefaultListCardImage,
  DefaultListCardTitle,
  DefaultListCardValue,
  DefaultListCardsContainer,
  DefaultListContainer,
  Title,
} from './styles';

const DefaultList: React.FC<{
  data: Timeline[];
}> = ({ data }) => {
  const language = getLanguage();
  const title = TRANSLATIONS[language].DEFAULT_LIST_TITLE;

  return (
    <DefaultListContainer>
      <Title>{title}</Title>
      <DefaultListCardsContainer>
        {data.map((item, index) => {
          const { projectedValues } = item;
          const [value] = projectedValues;
          const { imageDesktopSmall, facetValue, label } = value;
          const placeholderThumbNailUrl = getPlaceHolderThumbNail();
          const { url = placeholderThumbNailUrl, title } =
            imageDesktopSmall || {};

          return (
            <DefaultListCard key={index}>
              <DefaultListCardImage src={url} alt={title} />
              <DefaultListCardContent>
                <DefaultListCardTitle>{label}</DefaultListCardTitle>
                <DefaultListCardValue>{facetValue}</DefaultListCardValue>
              </DefaultListCardContent>
            </DefaultListCard>
          );
        })}
      </DefaultListCardsContainer>
    </DefaultListContainer>
  );
};

export default DefaultList;
