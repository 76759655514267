export const getHostName = (): string => {
  return window.location.hostname || '';
};

export const getTopLevelDomain = (): string => {
  return getHostName()
    .split('.')
    .filter((_, index) => index > 0)
    .join('.');
};
