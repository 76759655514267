/* istanbul ignore file */
import { useState } from 'react';
import { Timeline } from '../../pages/timeline/timeline.model';

const useTimelineState = () => {
  const [subSteps, updateSubSteps] = useState<Timeline[]>([]);
  const [currentSubStep, updateCurrentSubStep] = useState<number>(0);

  const resetTimelineState = () => {
    updateSubSteps([]);
    updateCurrentSubStep(0);
  };

  return {
    subSteps,
    currentSubStep,
    updateSubSteps,
    updateCurrentSubStep,
    resetTimelineState,
  };
};

export default useTimelineState;
