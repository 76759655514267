import { noop } from '../../utils';
import { MeasurementDataModel } from './measurement-state.model';

export const MEASUREMENT_DATA_INITIAL_STATE: MeasurementDataModel = {
  viewSelectedOptions: {},
  minAndMaxValues: [],
  filteredFacets: [],
};

export const initialMeasurementContext = {
  measurementState: MEASUREMENT_DATA_INITIAL_STATE,
  updateMeasurementData: noop,
  resetMeasurementData: noop,
};
