import { get } from 'lodash';
import { MeasurementPage } from './measurement.model';
import { removeSpacesAndLowerCase } from '../../utils';

export const getAttributeNames = (pageData: MeasurementPage) => {
  const inputFields = get(pageData, 'inputFields', []);
  return inputFields.map((inputData) => {
    return inputData.attributeName;
  });
};

export const lowerCasedAttributeNames = (attributeNames: string[]) => {
  return attributeNames.map((attributeName) => {
    return removeSpacesAndLowerCase(attributeName);
  });
};

export const getMinAndMaxValues = (facets: any, attributeNames: string[]) => {
  const formattedAttributeNames = lowerCasedAttributeNames(attributeNames);
  const requiredFacets = facets.filter((facet: any) =>
    formattedAttributeNames.includes(removeSpacesAndLowerCase(facet.label))
  );

  const minMaxValues = requiredFacets.map((facet: any) => {
    const facetValues = get(facet, 'values', []);
    let minValue = +Infinity;
    let maxValue = -Infinity;
    for (const facetValue of facetValues) {
      const { label } = facetValue;
      const [start, end] = label
        .split('-')
        .map((value: string) => parseInt(value));
      if (start < minValue) {
        minValue = start;
      }
      if (end > maxValue) {
        maxValue = end;
      }
    }

    return { minValue, maxValue, attributeName: facet.label };
  });

  return { minMaxValues, filteredFacets: requiredFacets };
};

export const getSelectedOptionsRanges = (
  selectedValues: Record<string, string>,
  filteredFacets: Record<string, any>[]
): Record<string, string> => {
  let selectedOptionRanges = {};
  for (const [attributeName, selectedValue] of Object.entries(selectedValues)) {
    const requiredFacetData = filteredFacets.find(
      (facet) =>
        removeSpacesAndLowerCase(facet.label) ===
        removeSpacesAndLowerCase(attributeName)
    );
    if (!requiredFacetData) {
      continue;
    }
    const facetValues = get(requiredFacetData, 'values', []);
    const selectedValueRange = facetValues.find(
      (facetValue: { label: string }) => {
        const { label } = facetValue;
        const [start, end] = label
          .split('-')
          .map((value: string) => parseInt(value));
        return (
          start <= parseInt(selectedValue) && end >= parseInt(selectedValue)
        );
      }
    );

    const selectedValueRangeLabel = get(
      selectedValueRange,
      'label',
      ''
    ) as string;
    selectedOptionRanges = {
      ...selectedOptionRanges,
      [attributeName]: selectedValueRangeLabel,
    };
  }

  return selectedOptionRanges;
};

export const getMeasurementSelectedOptions = (
  measurementState: any,
  filteredFacets: Record<string, any>[]
) => {
  const selectedValues = Object.keys(measurementState).reduce(
    (acc, attributeName) => {
      const value = measurementState[attributeName];
      if (['viewSelectedOptions', 'minAndMaxValues'].includes(attributeName)) {
        return acc;
      }
      return {
        ...acc,
        [attributeName]: value,
      };
    },
    {}
  );

  const selectedOptionRanges = getSelectedOptionsRanges(
    selectedValues,
    filteredFacets
  );

  return selectedOptionRanges;
};
