import styled from 'styled-components';
import { COLORS, FONT_SIZES } from '../../../utils/constants/styles';

export const VerticalStepperContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90px;
`;

export const TimeLineContainer = styled.div`
  display: flex;
`;

export const TimeLine = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const Circle = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${COLORS.PRIMARY};
  cursor: pointer;

  &.current {
    transform: scale(1.5);
    pointer-events: none;
  }
`;

export const Line = styled.div`
  width: 0.5px;
  flex-grow: 1;
  background-color: ${COLORS.PRIMARY};
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 4px;
  font-size: ${FONT_SIZES.SMALL};
  line-height: 16px;
  margin-top: -4px;
  color: ${COLORS.NEUTRAL_GRAY_MEDIUM};
  cursor: pointer;
  margin-bottom: 16px;

  &.current {
    pointer-events: none;
  }
`;

export const Label = styled.div`
  &.current {
    font-weight: 700;
  }
`;

export const Value = styled.div``;
