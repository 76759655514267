import styled from 'styled-components';
import { COLORS, FONT_SIZES } from '../../../utils/constants/styles';

export const GridContainer = styled.div`
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
`;

export const GridCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  border: 0.5px solid ${COLORS.MEDIUM_GRAY};
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  width: 140px;

  &:hover {
    background-color: ${COLORS.NEUTRAL_GRAY_LIGHT};
    transform: scale(1.05);
  }
`;

export const GridCardImageWrapper = styled.div`
  width: 100%;
  height: 100px;
  border-radius: 3px 3px 0 0;

  img {
    border-radius: 3px 3px 0 0;
  }
`;

export const GridCardTitle = styled.div`
  font-size: ${FONT_SIZES.REGULAR};
  font-weight: 700;
  line-height: 20px;
  color: ${COLORS.DARK_GRAY};
  padding: 8px;
`;
