import { useContext, useEffect, useState } from 'react';
import { getCurrentTenant, removeSpacesAndLowerCase } from '../../utils';
import {
  API_STATUS,
  DEFAULT_BACK_BUTTON_TEXT,
  DEFAULT_NEXT_BUTTON_TEXT,
  INPUT_TYPES,
} from '../../utils/constants';
import { MeasurementPage } from './measurement.model';
import { MeasurementDataContext } from '../../providers/measurement';
import { ERROR_TYPES } from './constants';
import { ApiStatusType } from '../../utils/common.model';
import { getFacetsData } from '../../services/products.service';
import {
  getAttributeNames,
  getMeasurementSelectedOptions,
  getMinAndMaxValues,
} from './measurementHelpers';
import { AppContext } from '../../providers';
import { TimeLineContext } from '../../providers/timeline';
import { getLatestSelectedOptions } from '../../utils/timelineHelper';
import { ERROR_MESSAGES } from '../timeline/constants';
import { get } from 'lodash';
import usePageWrapper from '../page-wrapper/usePageWrapper';

const useMeasurement = (data: MeasurementPage) => {
  const { measurementState, updateMeasurementData } = useContext(
    MeasurementDataContext
  );
  const { appState, appActions } = useContext(AppContext);
  const { subSteps } = useContext(TimeLineContext);
  const { goToPreviousPage } = usePageWrapper();

  const [selectionApiStatus, updateSelectionApiStatus] =
    useState<ApiStatusType>(API_STATUS.IDLE);
  const tenant = getCurrentTenant();
  const { categoryId, stepper, pages } = appState;
  const { currentStep } = stepper;
  const { minAndMaxValues, filteredFacets } = measurementState;
  const selectedOptions = getLatestSelectedOptions(subSteps);

  useEffect(() => {
    fetchFacetData();
  }, []);

  const handleError = (facetsData: Record<string, any>) => {
    const isError = get(facetsData, 'isError', false);
    if (isError) {
      updateSelectionApiStatus(API_STATUS.ERROR);
      if (facetsData.error === ERROR_MESSAGES.FACET_NOT_FOUNT) {
        // TODO: handle errors properly
      }
    }

    return isError;
  };

  const fetchFacetData = async () => {
    // If we have already fetched the data then no need to fetch it again
    // This will happen when we come back from next Pages

    if (minAndMaxValues.length > 0) {
      return;
    }
    updateSelectionApiStatus(API_STATUS.LOADING);
    const attributeNames = getAttributeNames(data);
    const facetsData = await getFacetsData(
      tenant,
      categoryId,
      attributeNames,
      selectedOptions
    );
    const isError = handleError(facetsData);
    if (isError) return;
    const { facets } = facetsData;
    const { minMaxValues, filteredFacets } = getMinAndMaxValues(
      facets,
      attributeNames
    );
    updateMeasurementData({
      ...measurementState,
      minAndMaxValues: minMaxValues,
      filteredFacets,
    });
    updateSelectionApiStatus(API_STATUS.SUCCESS);
  };

  const {
    title = '',
    pageTitle = '',
    noteTitle = '',
    noteDescription = '',
    nextButtonText = DEFAULT_NEXT_BUTTON_TEXT,
    backButtonText = DEFAULT_BACK_BUTTON_TEXT,
    imageDesktop = { url: '', title: '' },
    inputFields = [],
  } = data;

  const imageUrl = get(imageDesktop, 'url', '');
  const imageTitle = get(imageDesktop, 'title', '');

  const onInputValueChange = (
    inputData: MeasurementPage['inputFields'][0],
    value: string
  ) => {
    const { attributeName, measurementUnit, label } = inputData;
    const updatedLabel = label.split('(')[0].trim();
    const updatedState = {
      ...measurementState,
      [attributeName]: value,
      viewSelectedOptions: {
        ...measurementState.viewSelectedOptions,
        [updatedLabel]: `${value} ${measurementUnit}`,
      },
    };

    updateMeasurementData(updatedState);
  };

  const isError = (
    attributeName: string,
    value: string,
    minErrorMessage: string,
    maxErrorMessage: string
  ) => {
    const parsedValue = parseInt(value);
    const minMaxValue = minAndMaxValues.find(
      (value) =>
        removeSpacesAndLowerCase(value.attributeName) ===
        removeSpacesAndLowerCase(attributeName)
    );
    const minValue = get(minMaxValue, 'minValue', 0);
    const maxValue = get(minMaxValue, 'maxValue', 0);

    if (parsedValue < minValue) {
      return {
        isError: true,
        errorType: ERROR_TYPES.MIN,
        errorMessage: minErrorMessage + ' ' + minValue,
      };
    }
    if (parsedValue > maxValue) {
      return {
        isError: true,
        errorType: ERROR_TYPES.MAX,
        errorMessage: maxErrorMessage + ' ' + maxValue,
      };
    }
    return {
      isError: false,
      errorType: '',
      errorMessage: '',
    };
  };

  const parseInputsData = (inputsData: MeasurementPage['inputFields']) => {
    const inputFields = inputsData.map((inputData) => {
      const {
        labelIcon,
        label = '',
        placeholderValue = '',
        type = INPUT_TYPES.NUMBER,
        attributeName = '',
      } = inputData;

      const latestValue = get(measurementState, attributeName, '') as string;
      const minErrorMessage = get(inputData, 'minErrorMessage', '');
      const maxErrorMessage = get(inputData, 'maxErrorMessage', '');

      return {
        labelIcon: {
          ...labelIcon,
          url: get(labelIcon, 'url', ''),
        },
        label,
        placeholderValue,
        type,
        attributeName,
        value: latestValue,
        measurementUnit: inputData?.measurementUnit,
        onChange: onInputValueChange,
        ...isError(
          attributeName,
          latestValue,
          minErrorMessage,
          maxErrorMessage
        ),
      };
    });

    return inputFields;
  };

  const parsedInputsData = parseInputsData(inputFields);
  const isNextButtonDisabled =
    parsedInputsData.length === 0 ||
    parsedInputsData.some((inputData) => inputData.isError || !inputData.value);

  const goToNextPage = async () => {
    if (currentStep >= pages.length - 1) {
      return;
    }
    updateSelectionApiStatus(API_STATUS.LOADING);
    const measurementStateSelectedOptions = getMeasurementSelectedOptions(
      measurementState,
      filteredFacets
    );
    const latestSelectedOptions = {
      ...selectedOptions,
      ...measurementStateSelectedOptions,
    };
    const facetsData = await getFacetsData(
      tenant,
      categoryId,
      [],
      latestSelectedOptions
    );
    const isError = handleError(facetsData);
    if (isError) return;
    const availableProducts = get(facetsData, 'products', []);
    const parsedProducts = availableProducts.map((product: any) => ({
      productId: product.id,
      variantId: product.variantId,
    }));
    appActions.updateAppData({
      ...appState,
      filteredProducts: parsedProducts,
    });
    appActions.updateStepperInfo({ currentStep: currentStep + 1 });
    updateSelectionApiStatus(API_STATUS.SUCCESS);
  };

  return {
    title,
    pageTitle,
    noteTitle,
    noteDescription,
    nextButtonText,
    backButtonText,
    imageUrl,
    imageTitle,
    inputsData: parsedInputsData,
    selectionApiStatus,
    isNextButtonDisabled,
    goToNextPage,
    goToPreviousPage,
    ...measurementState,
  };
};

export default useMeasurement;
