/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useState } from 'react';

const usePageContentWrapper = ({
  imageUrl,
  imageContentTitle,
  imageDescription,
}: {
  imageUrl: string;
  imageContentTitle: string;
  imageDescription: string;
}) => {
  const [hoveredImageDetails, setHoveredImageDetails] = useState<{
    imageUrl: string | null;
    imageTitle: string | null;
    imageDesc: string | null;
  }>({
    imageUrl: null,
    imageTitle: null,
    imageDesc: null,
  });

  const handleOnMouseEnter = (
    url: string,
    title: string,
    desc: string | null
  ) => {
    setHoveredImageDetails({
      imageUrl: url,
      imageTitle: title,
      imageDesc: desc,
    });
  };

  const handleOnMouseLeave = () => {
    setHoveredImageDetails({
      imageUrl: null,
      imageTitle: null,
      imageDesc: null,
    });
  };

  const mainImageUrl = hoveredImageDetails.imageUrl || imageUrl;

  let imageTitle: string;
  let imageDesc: string;

  // If the image is hovered, show the title and description of the hovered image
  if (hoveredImageDetails.imageUrl) {
    if (hoveredImageDetails.imageDesc) {
      imageTitle = hoveredImageDetails.imageTitle!;
      imageDesc = hoveredImageDetails.imageDesc!;
    } else {
      imageTitle = '';
      imageDesc = '';
    }
    // If the image is clicked, show the title and description of the clicked image
  } else {
    if (imageDescription) {
      imageTitle = imageContentTitle;
      imageDesc = imageDescription;
    } else {
      imageTitle = '';
      imageDesc = '';
    }
  }

  return {
    mainImageUrl,
    imageTitle,
    imageDesc,
    handleOnMouseEnter,
    handleOnMouseLeave,
  };
};

export default usePageContentWrapper;
