/**
 * Mobile   [320...720]
 *
 * Tablet  [720...1024]
 *
 * Desktop   [1024...1280]
 *
 * SuperDesktop  [1280...infinite]
 */

export const BREAKPOINTS = {
  mobile: 320,
  tablet: 720,
  desktop: 1024,
  superDesktop: 1280,
} as const;

export const DEVICE_TYPES = {
  mobile: 'mobile',
  tablet: 'tablet',
  desktop: 'desktop',
  superDesktop: 'superDesktop',
} as const;
