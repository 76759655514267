import styled from 'styled-components';
interface ImageElModel {
  borderRadius: string;
  objectFit: 'cover' | 'contain' | 'fill' | 'none' | 'scale-down';
}
export const ImageEl = styled.img<ImageElModel>`
  width: 100%;
  height: 100%;
  object-fit: ${({ objectFit }: ImageElModel) => objectFit};
  border-radius: ${({ borderRadius }: ImageElModel) => borderRadius};
`;
