import DefaultList from '../../../components/default-list';
import Image from '../../../components/image';
import MobileSubStepper from '../../../components/mobile-sub-stepper';
import NextAndBackButtons from '../../../components/next-and-back-buttons';
import Swipper from '../../../components/swiper';
import { IMAGE_TYPES } from '../../../utils/constants';
import { TimelinePageProps } from '../timeline.model';
import {
  DefaultListWrapper,
  ImageWrapper,
  ImageContainer,
  ImageContent,
  ImageDescription,
  ImageTitle,
  NextAndPreviousButtonsContainer,
  PageContainer,
  PageSecondaryTitle,
  PageTitle,
  TitlesContainer,
} from './styles';

const TimelineMobile: React.FC<TimelinePageProps> = ({
  currentSubStep,
  subStepsWhichHaveOneValue,
  backButtonText,
  imageContentTitle,
  imageDescription,
  nextButtonText,
  isContinueButtonEnabled,
  isNextSubStepsHaveOnlyOneOption,
  subStepperData,
  optionalPageTitle,
  pageTitle,
  currentSubStepData,
  imageAlt,
  imageUrl,
  onCardClick,
  goToNextPageOrSubStep,
  goToPreviousPageOrSubStep,
  goToClickedSubStep,
}) => {
  return (
    <PageContainer>
      <MobileSubStepper
        data={subStepperData}
        currentSubStep={currentSubStep}
        goToClickedSubStep={goToClickedSubStep}
      />
      <TitlesContainer>
        {optionalPageTitle && <PageTitle>{optionalPageTitle}</PageTitle>}
        <PageSecondaryTitle>{pageTitle}</PageSecondaryTitle>
      </TitlesContainer>
      <ImageContainer>
        <ImageWrapper>
          <Image
            dataTestId="mobile-main-image"
            src={imageUrl}
            alt={imageAlt}
            imageType={IMAGE_TYPES.MOBILE_MAIN}
          />
        </ImageWrapper>
        {imageContentTitle && imageDescription && (
          <ImageContent>
            <ImageTitle>{imageContentTitle}</ImageTitle>
            <ImageDescription>{imageDescription}</ImageDescription>
          </ImageContent>
        )}
      </ImageContainer>
      <Swipper data={currentSubStepData} onCardClick={onCardClick} />
      {isNextSubStepsHaveOnlyOneOption && (
        <DefaultListWrapper>
          <DefaultList data={subStepsWhichHaveOneValue} />
        </DefaultListWrapper>
      )}
      <NextAndPreviousButtonsContainer>
        <NextAndBackButtons
          nextButtonText={nextButtonText}
          backButtonText={backButtonText}
          isDisabled={!isContinueButtonEnabled}
          goToNextPageFn={goToNextPageOrSubStep}
          goToPreviousPageFn={goToPreviousPageOrSubStep}
          useCustomFunctions={true}
        ></NextAndBackButtons>
      </NextAndPreviousButtonsContainer>
    </PageContainer>
  );
};

export default TimelineMobile;
