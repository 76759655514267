import { useContext, useEffect, useState } from 'react';
import { AddToCartPage } from './addtocart.model';
import { AppContext } from '../../providers';
import useDevice from '../../hooks/useDevice';
import { addToCart } from '../../services/addtocart/addtocart.service';
import {
  API_STATUS,
  DEAFULT_ADD_TO_CART_BUTTON_TEXT,
  DEFAULT_BACK_BUTTON_TEXT,
} from '../../utils/constants';
import { AddToCartContext } from '../../providers/addtocart';
import { TimeLineContext } from '../../providers/timeline';
import { ApiStatusType } from '../../utils/common.model';
import { MeasurementDataContext } from '../../providers/measurement';
import { getProductDetails } from '../../services/product-details.service';
import { getTimelineViewSelectedOptions } from './addToCartHelper';
import { get } from 'lodash';

const useAddToCart = (addToCartPageData: AddToCartPage) => {
  const { appState } = useContext(AppContext);
  const {
    productTitle,
    productCode,
    brandName,
    images,
    priceInfo,
    totalPrice,
    serviceSkuId,
    sellerSkuId,
    setAddToCartData,
  } = useContext(AddToCartContext);
  const { subSteps } = useContext(TimeLineContext);
  const selectedAttributes: Record<string, string> =
    getTimelineViewSelectedOptions(subSteps);

  const {
    measurementState: { viewSelectedOptions: selectedMeasurements },
  } = useContext(MeasurementDataContext);

  let selectedSpecs = { ...selectedAttributes, ...selectedMeasurements };
  if (selectedMeasurements.ancho && selectedMeasurements.alto) {
    const formattedMeasurements = {
      Medidas: `${selectedMeasurements.ancho} x ${selectedMeasurements.alto}`,
    };
    selectedSpecs = {
      ...selectedAttributes,
      ...formattedMeasurements,
    };
  }
  const { isMobile } = useDevice();
  const [isModalOpen, updateIsModalOpen] = useState(false);
  const [productDetailsApiStatus, updateProductDetailsApiStatus] =
    useState<ApiStatusType>(API_STATUS.IDLE);
  const [addToCartApiStatus, updateAddToCartApiStatus] =
    useState<ApiStatusType>(API_STATUS.IDLE);
  const { isProductDetailsPageAvailable, filteredProducts, journeysType } =
    appState;
  const selectedProduct = get(filteredProducts, '[0]', {});
  const productId = get(selectedProduct, 'productId', '');
  const variantId = get(selectedProduct, 'variantId', '');

  const {
    pageTitle,
    nextButtonText = DEAFULT_ADD_TO_CART_BUTTON_TEXT,
    backButtonText = DEFAULT_BACK_BUTTON_TEXT,
    productType = 'Product',
    cartUrl = '',
    isCartPageOpenInNewTab = false,
    attributeMapping = [],
  } = addToCartPageData;
  const parsedSelectedSpecs = Object.entries(selectedSpecs).map(
    ([key, value]) => ({ key, value })
  );
  const parsedPriceInfo = Object.entries(priceInfo).map(([key, value]) => ({
    key,
    value,
  }));
  const customInformation = {
    journeysType,
    productType,
    attributeMapping,
    selectedSpecs,
  };

  useEffect(() => {
    if (isProductDetailsPageAvailable) {
      // get data from products details state and update add to cart state
      // TODO: After product details page is finished
    } else {
      // call product details api
      initializeAddToCartState();
    }
  }, []);

  const initializeAddToCartState = async () => {
    if (!productId || !variantId) {
      updateAddToCartApiStatus(API_STATUS.ERROR);
    }
    updateProductDetailsApiStatus(API_STATUS.LOADING);
    const addToCartPageData = await getProductDetails(
      productId,
      variantId,
      productType
    );
    const { success, productDetails } = addToCartPageData;
    if (success) {
      setAddToCartData(productDetails);
      updateProductDetailsApiStatus(API_STATUS.SUCCESS);
    } else {
      updateProductDetailsApiStatus(API_STATUS.ERROR);
    }
  };

  const handleAddToCart = async () => {
    const body = {
      productId,
      skuId: variantId,
      quantity: 1,
      serviceSkuId, //It is the id from serviceOptions of product Details API Response
      sellerSkuId,
      customInformation,
    };
    updateAddToCartApiStatus(API_STATUS.LOADING);
    const { success } = (await addToCart(body)) || {};
    if (success) {
      updateIsModalOpen(true);
      updateAddToCartApiStatus(API_STATUS.SUCCESS);
    } else {
      updateAddToCartApiStatus(API_STATUS.ERROR);
    }
  };

  return {
    pageTitle,
    selectedSpecs: parsedSelectedSpecs,
    totalPrice,
    priceInfo: parsedPriceInfo,
    isMobile,
    cartUrl,
    isCartPageOpenInNewTab,
    isModalOpen,
    nextButtonText,
    backButtonText,
    productTitle,
    productCode,
    brandName,
    images,
    productDetailsApiStatus,
    addToCartApiStatus,
    isProductDetailsPageAvailable,
    handleAddToCart,
  };
};

export default useAddToCart;
